import container from "shared/inversify.config";
import { defineStore } from "pinia";
import { ref } from "vue";
import { Referral } from "user/models/referrals";
import { ReferralApi } from "user/api/referral";

export const useReferralStore = defineStore("referral", () => {
  const referrals = ref<Referral[]>();

  function $reset() {
    referrals.value = [];
  }

  async function get() {
    referrals.value = await container.get(ReferralApi).getReferrals();
  }

  async function apply(code: string) {
    return await container.get(ReferralApi).applyReferralCode(code);
  }

  return {
    $reset,
    get,
    apply,
    referrals: shallowReadonly(referrals),
  };
});
