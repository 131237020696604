<script setup lang="ts">
import star from "user/assets/icons/star-icon.png";
import gem from "user/assets/icons/gem-icon.png";
import { useSessionStore } from "user/stores/session";

const session = useSessionStore();
</script>
<template>
  <div class="flex justify-between mr-8 ml-2">
    <RouterLink
      to="/missions/missions/available"
      class="w-[141rem] h-[40rem] flex items-center relative"
    >
      <img :src="star" class="h-[42rem] z-10" alt="" />
      <div
        class="bg-mercury rounded-md w-full ml-5 h-[22rem] absolute text-black text-base pl-6 flex align-top font-semibold justify-between pr-4"
      >
        <span>
          {{ session.currentUser?.currencies.stars }}
        </span>
        <span> + </span>
      </div>
    </RouterLink>
    <RouterLink
      to="/market/gems"
      class="w-[141rem] h-[40rem] flex items-center relative"
    >
      <img :src="gem" class="h-[39rem] z-10" alt="" />
      <div
        class="bg-mercury rounded-md w-full ml-5 h-[22rem] absolute text-black text-base pl-8 flex align-top font-semibold justify-between pr-4"
      >
        <span>
          {{ session.currentUser?.currencies.gems }}
        </span>
        <span> + </span>
      </div>
    </RouterLink>
  </div>
</template>
