<script lang="ts" setup>
import Button, { ButtonSize } from "user/components/FlatButton.vue";
import accessoriesBackpack from "user/assets/icons/accessories-backpack.svg";
import starIcon from "user/assets/icons/star-icon.png";
import gemIcon from "user/assets/icons/gem-icon.png";
import getBackgroundColor from "user/utils/getBackgroundColor";
import { Reward, RewardClassName } from "user/models/items";

const props = defineProps<{
  open: boolean;
  loading?: boolean;
  item: Reward;
  gems?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:open", value: boolean): void;
  (event: "confirm"): void;
}>();

function getClassNameLabel(className?: RewardClassName) {
  if (!className) {
    return "";
  }

  switch (className) {
    case RewardClassName.Merch:
      return "Merch";
    case RewardClassName.STW:
      return "Spins";
    case RewardClassName.Badge:
      return "Badge";
    case RewardClassName.Gems:
      return "GEMs";
    case RewardClassName.Lootbox:
      return "Lootbox";
    case RewardClassName.Stars:
      return "STARs";
    case RewardClassName.UGC:
      return "UGC";
    default:
      return "";
  }
}

function onUpdateOpen(value: boolean) {
  // prevent closing the slide up while "loading"

  if (!props.loading) {
    emit("update:open", value);
  }
}

const confirmState = ref(false);

function toggleState(target: boolean) {
  confirmState.value = target;
}

function confirm() {
  confirmState.value = false;
  emit("confirm");
}
</script>

<template>
  <SlideUp :open="open" @update:open="onUpdateOpen">
    <div class="flex w-[320rem] flex-col gap-y-6">
      <!-- Content -->
      <div
        class="text-nl-0 flex flex-col gap-y-[20rem] text-center"
        :class="{ 'opacity-50': loading }"
      >
        <!-- <slot name="top" /> -->
        <span v-if="!confirmState">
          <div class="text-[20rem] font-semibold">{{ item?.name }}</div>
          <div v-if="item?.description" class="text-sm">
            {{ item.description }}
          </div>
        </span>
        <span v-if="confirmState">
          <div class="text-[20rem] font-semibold">
            Redeem
            {{
              gems ? `${item?.price?.stars} STARs` : `${item?.price?.gems} GEMs`
            }}
            for
            <span v-if="item.marketplace_quantity">
              {{ item.marketplace_quantity }}</span
            >
            "{{ item?.name }}"?
          </div>
        </span>
        <div v-if="item" class="self-center">
          <!-- maybe something like a Prize Media component that can be used everywhere? -->
          <div
            class="h-[320rem] w-[320rem] relative rounded-ml flex justify-center items-center overflow-clip shadow-home border-none"
            :class="[
              getBackgroundColor(item.rarity, 'radial', item.class_name),
            ]"
          >
            <img :src="item.icon" alt="" class="object-contain h-full w-full" />
            <DisplaySupply
              v-if="!item.infinity_allocation"
              class="text-[14rem] px-2 py-1"
              :allocated="item.allocated"
              :allocation="item.allocation"
            />
            <SoldOutLayer v-show="item.is_sold_out" />
          </div>
        </div>
      </div>

      <!-- TODO: might be worth making this into its own component, seems fairly dynamic and programatically styled -->
      <div v-if="!confirmState" class="flex h-[53rem] w-full gap-14">
        <div class="flex gap-3 justify-center w-full">
          <img :src="accessoriesBackpack" alt="" />
          <div class="text-lg font-bold">
            <div>Rarity</div>
            <div>
              {{ getClassNameLabel(item?.class_name) }}
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div class="flex flex-col items-center gap-y-3">
        <Button
          v-if="!confirmState && gems"
          :label="`${item?.price?.stars ?? 0}`"
          loading-label="Loading"
          :trailingIcon="starIcon"
          :loading="loading"
          @click="toggleState(true)"
          :disabled="item.is_sold_out"
        />
        <Button
          v-if="!confirmState && !gems"
          :label="`${item?.price?.gems ?? 0}`"
          loading-label="Loading"
          :trailingIcon="gemIcon"
          :loading="loading"
          @click="toggleState(true)"
          :disabled="item.is_sold_out"
        />
        <div v-if="confirmState" class="flex gap-2 flex-row">
          <Button
            label="Confirm"
            loading-label="Loading"
            :loading="loading"
            @click="confirm"
            :size="ButtonSize.sm"
            :disabled="item.is_sold_out"
          />
          <Button
            label="Maybe Later"
            loading-label="Loading"
            :loading="loading"
            @click="toggleState(false)"
            :size="ButtonSize.sm"
            hollow
          />
        </div>
      </div>
    </div>
  </SlideUp>
</template>
