import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { Reward, RewardClassName } from "user/models/items";
import { MarketApi } from "user/api/market";
import { useSessionStore } from "./session";

export interface Reciept {
  status?: string;
  purchase?: {
    purchase_id: string;
    user_id: number;
    reward_id: number;
    created_at: string;
    paid: number;
  };
}

type RewardWithWeight = Omit<Reward, "marketplace_weight"> & {
  marketplace_weight: number;
};

function sortByWeight(arr: Reward[]) {
  const withoutWeight: Reward[] = arr.filter(
    (el) =>
      el.marketplace_weight === null || el.marketplace_weight === undefined,
  );

  const withWeight: RewardWithWeight[] = arr.filter(
    (el) => el.marketplace_weight || el.marketplace_weight === 0,
  ) as RewardWithWeight[];

  const sorted: Reward[] = withWeight.sort(
    (a, b) => a.marketplace_weight - b.marketplace_weight,
  );

  return sorted.concat(withoutWeight);
}

function getPriceNumber(r: Reward): number {
  if (r.price?.gems) {
    return r.price?.gems;
  }
  if (r.price?.stars) {
    return r.price?.stars;
  }
  if (r.price?.vouchers) {
    return r.price?.vouchers;
  }

  return 0;
}

export const useMarketStore = defineStore("market", () => {
  const ugc = ref<Reward[]>();
  const all = ref<Reward[]>();
  const gems = ref<Reward[]>();
  const lootboxes = ref<Reward[]>();
  const spins = ref<Reward[]>();
  const session = useSessionStore();

  function $reset() {
    ugc.value = [];
    all.value = [];
    gems.value = [];
    lootboxes.value = [];
    spins.value = [];
  }

  async function fetchMarketplace() {
    const result = await container.get(MarketApi).getMarketplace();
    ugc.value = sortByWeight(result.ugcs);
    all.value = sortByWeight(result.all);
    gems.value = sortByWeight(result.gems);
    lootboxes.value = sortByWeight(result.lootboxes);
    let spinPrice = 0;

    spins.value = all.value
      .filter((el) => {
        if (
          el.class_name === RewardClassName.STW &&
          el.marketplace_quantity === 1
        ) {
          spinPrice = getPriceNumber(el);
        }
        return el.class_name === RewardClassName.STW;
      })
      .map((el) => {
        if (!spinPrice || el.marketplace_quantity === undefined) {
          return el;
        }
        if (el.marketplace_quantity > 1) {
          const p = el.marketplace_quantity * spinPrice;
          if (el.price?.gems && el.price?.gems !== p) {
            el.original_price = {};
            el.original_price.gems = p;
          }
          if (el.price?.stars && el.price?.stars !== p) {
            el.original_price = {};
            el.original_price.stars = p;
          }
          if (el.price?.vouchers) {
            el.original_price = {};
            el.original_price.vouchers = p;
          }
        }
        return el;
      });
  }

  async function buyItem(item_id: number): Promise<Reciept | boolean> {
    try {
      const res = await container.get(MarketApi).purchase(item_id);
      session.tryFetchCurrentUser();

      return res;
    } catch (err) {
      return false;
    }
  }

  async function openLootbox(item_id: number): Promise<Reward> {
    const res = await container.get(MarketApi).openLootbox(item_id);
    return res;
  }

  return {
    $reset,
    ugc: shallowReadonly(ugc),
    all: shallowReadonly(all),
    gems: shallowReadonly(gems),
    lootboxes: shallowReadonly(lootboxes),
    spins: shallowReadonly(spins),
    fetchMarketplace,
    buyItem,
    openLootbox,
  };
});
