<script setup lang="ts">
import { ref, computed } from "vue";
import { Reward } from "user/models/items";
import { useBuyStore } from "user/stores/buy";

const props = defineProps<{
  items: Reward[];
  ugc?: boolean;
  gems?: boolean;
}>();

const selectedIndex = ref<number>();
const buyStore = useBuyStore();

// fill the rest of the page with placeholders
const placeholders = computed(() =>
  6 - props.items.length > 0 ? 6 - props.items.length : 0,
);

function openDialog(item: Reward, index?: number) {
  selectedIndex.value = index;
  buyStore.buyItem(item);
}
</script>

<template>
  <div
    class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 gap-x-4 gap-y-6 px-[1px] py-6"
  >
    <MarketListCard
      v-for="(item, index) of items"
      :key="index"
      :item="item"
      :selected="index === selectedIndex"
      @click="openDialog(item, index)"
      :gems="gems"
    />

    <ListCardPlaceholder v-for="(_, index) of placeholders" :key="index" />

    <BuyModal />
  </div>
</template>
