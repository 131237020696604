<script setup lang="ts">
import { useReferralStore } from "user/stores/referral";

defineProps<{
  open: boolean;
}>();

const referralStore = useReferralStore();
const referralCode = ref("");
const isError = ref(false);
const isSuccess = ref(false);
const isInProgress = ref(false);

const emit = defineEmits<{
  (event: "update:open", value: boolean): void;
  (event: "confirm"): void;
}>();

function onUpdateOpen(value: boolean) {
  emit("update:open", value);
  clearErrors();
}

async function handleApplyCode() {
  if (isInProgress.value === true) {
    return;
  }

  clearErrors();
  try {
    isInProgress.value = true;
    await referralStore.apply(referralCode.value);
    isSuccess.value = true;
    setTimeout(() => {
      emit("confirm");
    }, 1500);
  } catch (error) {
    console.log("Error:", error);
    isError.value = true;
  } finally {
    isInProgress.value = false;
  }
}

function clearErrors() {
  isSuccess.value = false;
  isError.value = false;
}

onMounted(() => {
  clearErrors();
});
</script>

<template>
  <SlideUp
    :open="open"
    @update:open="onUpdateOpen"
    :class="{ 'cursor-wait': isInProgress }"
  >
    <div class="flex flex-col gap-8 px-4 mt-[50rem]">
      <div>
        <h3 class="text-[20rem]/[28rem] text-center font-semibold">
          Enter A Referral Code
        </h3>
      </div>

      <p class="text-[15rem]/[22rem] text-[#6A6A6A] text-center">
        If you forgot to sign up with a link, you can add it here. You and your
        friend will be one step closer to your referral rewards.
      </p>

      <div class="flex flex-col gap-4 w-4/5 mx-auto">
        <FunInput
          id="enterCode"
          class="text-[15rem]/[22rem] shadow-home rounded-[6rem] text-center"
          placeholder="Enter code"
          v-model="referralCode"
          :error="isError"
          :class="{
            'text-jadeMountain border-jadeMountain border-[2rem]': isSuccess,
            'cursor-wait': isInProgress,
          }"
        />
        <span
          v-if="isError"
          class="text-[#FF0000] text-[15rem]/[22rem] text-center"
          >Invalid code. Please try again!</span
        >
        <ButtonClient
          kind="black"
          @click="handleApplyCode"
          :class="{
            'bg-jadeMountain': isSuccess,
            'cursor-wait': isInProgress,
          }"
        >
          <span class="text-[20rem]/[20rem] font-semibold">Submit</span>
        </ButtonClient>
      </div>
    </div>
  </SlideUp>
</template>
