<script setup lang="ts">
import { useOnboardingStore } from "user/stores/onboarding";
import { useNotificationStore } from "user/stores/notifications";
import { useBoothStore } from "user/stores/booth";
import { Reward, rewardKindFromClassName } from "user/models/items";
import { RewardKind } from "user/models/items";
import { OnboardingReward } from "user/models/initial-rewards";

const onboardingStore = useOnboardingStore();
const notificationStore = useNotificationStore();
const boothStore = useBoothStore();

const router = useRouter();

const openPrizes = ref(false);

const openNotification = ref(false);

const active = computed(() => router.currentRoute.value.name !== "login");

function reset() {
  openPrizes.value = false;
  openNotification.value = false;
}

function stepPrizes() {
  const proceed = onboardingStore.stepRewardsAndProgress();
  if (proceed) {
    openPrizes.value = false;
    // if (onboardingStore.checkInitialSpin() != "true") {
    //   router.push("spin-the-wheel/true");
    // }
  }
}
function stepNotifications() {
  const proceed = notificationStore.stepNotificationAndProgress();
  if (proceed) {
    openNotification.value = false;
    // router.push({ name: "backpack-ugc" });
  }
}

onMounted(async () => {
  setTimeout(async () => {
    await boothStore.triggerBooth();
    await Promise.all([
      onboardingStore.fetchOnboardingRewards(),
      notificationStore.fetchNotifications(),
    ]);
    if (
      onboardingStore.pendingRewards &&
      onboardingStore.pendingRewards !== null &&
      onboardingStore.pendingRewards.length > 0
    ) {
      openPrizes.value = true;
      openNotification.value = false;
    } else if (notificationStore.notificationQueue.length > 0) {
      openNotification.value = true;
      openPrizes.value = false;
    }
  }, 200);
});

watchEffect(() => {
  if (notificationStore.notificationQueue.length > 0) {
    openNotification.value = true;
    openPrizes.value = false;
  }
});
</script>

<template>
  <!-- <div
    v-if="openPrizes && active"
    v-for="(item, index) in onboardingStore.pendingRewards"
    :key="item.id"
  >
     <Initial50Modal
      :open="openThisGift(index, item, RewardKind.Stars)"
      @close="stepPrizes"
      :reward="item"
    />
    <OnboardingLootboxModal
      :open="openThisGift(index, item, RewardKind.Lootbox)"
      @close="stepPrizes"
      :prize="item"
    />
    <OnboardingGiftModal
      :open="openThisGift(index, item)"
      @close="stepPrizes"
      :prize="item"
    />
     <ReceiveModalLayout
      :open="index === onboardingStore.pendingRewardIndex"
      @update:open="stepPrizes"
      :prize="item"
      @continue="stepPrizes"
      is-onboarding
    />
  </div> -->
  <div
    v-if="openNotification && active"
    v-for="(item, index) in notificationStore.notificationQueue"
    :key="index"
  >
    <div v-if="item.reward">
      <ReceiveModalLayout
        :open="index === notificationStore.indexInQueue"
        @update:open="stepNotifications"
        :prize="item.reward"
        @continue="stepNotifications"
        is-onboarding
      />
    </div>
  </div>
</template>
