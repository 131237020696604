<script setup lang="ts">
import Discord from "user/assets/discord-black.svg";
import twitter from "user/assets/x-logo.svg";
import roblox from "user/assets/roblox.svg";
import { useSessionStore } from "user/stores/session";
import { ExternalServices } from "user/models/user";

const userStore = useSessionStore();

const showDiscord = computed(() => {
  return !userStore.currentUser?.authorization_providers.some(
    (e) => e == ExternalServices.Discord,
  );
});
const discordForm = ref<HTMLFormElement>();

const discordFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=discord",
  );
  return `/auth/discord`;
});

const showTwitter = computed(() => {
  return !userStore.currentUser?.authorization_providers.some(
    (e) => e == ExternalServices.Twitter,
  );
});
const twitterForm = ref<HTMLFormElement>();

const twitterFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=twitter",
  );
  return `/auth/twitter2`;
});

const showRoblox = computed(() => {
  return !userStore.currentUser?.authorization_providers.some(
    (e) => e == ExternalServices.Roblox,
  );
});
const robloxForm = ref<HTMLFormElement>();

const robloxFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=roblox",
  );
  return `/auth/roblox`;
});

async function discordLogin() {
  // await sessionStore.initiateOauthGoogle();
  discordForm.value!.submit();
}

function twitterLogin() {
  twitterForm.value!.submit();
}

function robloxLogin() {
  robloxForm.value!.submit();
}
</script>
<template>
  <div class="flex gap-x-[10rem] w-full z-10 px-[23rem]">
    <div
      v-if="showDiscord"
      class="h-[108rem] w-[108rem] flex flex-col shadow-home justify-center items-center text-base gap-y-1 rounded-ml bg-white"
      @click="discordLogin()"
    >
      <img :src="Discord" alt="" class="w-[41rem] rounded-ml" />
      Discord
    </div>
    <div
      v-if="showTwitter"
      class="h-[108rem] w-[108rem] flex flex-col shadow-home justify-center items-center text-base gap-y-1 rounded-ml bg-white"
      @click="twitterLogin()"
    >
      <img :src="twitter" alt="" class="w-[41rem] rounded-ml" />
      X
    </div>
    <div
      v-if="showRoblox"
      class="h-[108rem] w-[108rem] flex flex-col shadow-home justify-center items-center text-base gap-y-1 rounded-ml bg-white"
      @click="robloxLogin()"
    >
      <img :src="roblox" alt="" class="w-[41rem] rounded-ml" />
      Roblox
    </div>
  </div>
  <div>
    <form ref="discordForm" method="GET" :action="discordFormAction"></form>
    <form ref="twitterForm" method="GET" :action="twitterFormAction"></form>
    <form ref="robloxForm" method="GET" :action="robloxFormAction"></form>
  </div>
</template>
