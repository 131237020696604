import { injectable } from "inversify";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";

@injectable()
export class BoothApi {
  axios = container.get(Api);

  async boothCompletion(token: string): Promise<void> {
    await this.axios.get(`/missions/completion_token/${token}`);
  }
}
