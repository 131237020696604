<script setup lang="ts">
import type { DotLottiePlayer } from "@aarsteinmedia/dotlottie-player";

import animation from "user/assets/lotties/spin-the-wheel/button_animation.json";

const props = defineProps<{
  animate?: boolean;
  freeSpinAvailable: boolean;
}>();

const playerRef = ref<DotLottiePlayer>();
const frame = ref(0);

const emit = defineEmits<{
  done: [];
}>();

watch(
  () => props.animate,
  () => {
    if (props.animate) {
      playerRef.value?.play();
    }
  },
);
onMounted(() => {
  playerRef.value?.addEventListener("ended", () => {
    emit("done");
  });

  playerRef.value?.addEventListener("frame", () => {
    frame.value++;
  });

  playerRef.value?.addEventListener("play", () => {
    console.log("play");
  });
});
</script>
<template>
  <dotlottie-player
    ref="playerRef"
    :src="animation"
    class="fixed bottom-[70rem] md:pr-[260rem] lg:pr-[414rem] md:bottom-[0rem]"
    :class="{
      'h-[85%] md:h-[90%]': !freeSpinAvailable,
      'h-[90%] md:h-[100%]': freeSpinAvailable,
    }"
  />
</template>
