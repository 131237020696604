<script setup lang="ts">
import share from "user/assets/icons/share.svg";
import Stars from "user/assets/STARs.png";
import { CurrencyKind } from "user/models/settings";
import { useReferralStore } from "user/stores/referral";
import { useSettingStore } from "user/stores/settings";
const router = useRouter();

const settingsStore = useSettingStore();
const referralStore = useReferralStore();

// const doneItems = computed(() => {
//   const totalItems = referralStore.referrals?.length ?? 0;
//   return totalItems % 3
// });

const types = {
  [CurrencyKind.GEMs]: "GEMs",
  [CurrencyKind.STARs]: "STARs",
};

function handleShare() {
  router.push({ name: "profile-referrals" });
}
</script>

<template>
  <div
    class="flex items-center bg-white justify-between rounded-ml shadow-referral min-w-[350rem] px-4 py-2 mx-[10rem] mt-[5rem] mb-[15rem]"
  >
    <div class="flex items-center h-full gap-3">
      <img :src="Stars" class="h-[40rem] w-[40rem] object-contain" />
      <div class="flex flex-col gap-1 items-start justify-between h-full">
        <div class="font-bold text-lg">
          Earn {{ settingsStore?.settings?.referral_reward_allocation }}
          {{ types[settingsStore?.settings?.referral_reward_type] }}
        </div>
        <div class="text-[14rem]/[18rem]">Invite a friend to PLAYSIDE</div>
        <div
          class="flex h-6 w-[92rem] text-xs leading-3 items-center rounded-full bg-black justify-center gap-2 text-white"
          @click="handleShare"
        >
          <img :src="share" alt="Share icon" />
          <span>Share</span>
        </div>
      </div>
    </div>
    <!-- <CircleIcon class="w-1/5">{{ doneItems }}/3 </CircleIcon> -->
  </div>
</template>
