<script lang="ts">
export enum ButtonSize {
  sm = "sm",
  md = "md",
  lg = "lg",
}
</script>
<script setup lang="ts">
withDefaults(
  defineProps<{
    label: string;
    hollow?: boolean;
    icon?: string;
    trailingIcon?: string;
    size?: ButtonSize;
    type?: "button" | "submit" | "reset";
    borderless?: boolean;
    play?: boolean;
    disabled?: boolean;
  }>(),
  {
    size: ButtonSize.lg,
  },
);

const buttonRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonRef,
});
</script>

<template>
  <button
    ref="buttonRef"
    :type="type ?? 'button'"
    class="flex h-[58rem] shrink-0 appearance-none items-center justify-center rounded-lg px-3 text-xl font-semibold"
    :class="{
      'bg-white text-black': hollow,
      'bg-black text-white': !hollow,
      'bg-jadeMountain': play,
      'border-[1rem] border-black': !borderless,
      'w-[160rem]': size == ButtonSize.sm,
      'w-[236rem]': size == ButtonSize.md,
      'w-[325rem]': size == ButtonSize.lg,
      '!bg-[#8a8a8a]': disabled,
      'cursor-not-allowed': disabled,
    }"
    :disabled="disabled"
  >
    <img v-if="icon" :src="icon" class="max-h-[22rem] pr-3" />
    {{ label }}
    <img v-if="trailingIcon" :src="trailingIcon" class="pl-3 h-[40rem]" />
  </button>
</template>
