<script setup lang="ts">
import { useSettingStore, DefaultOrder } from "user/stores/settings";
import { storeToRefs } from "pinia";
import { useBreakpoints } from "@vueuse/core";

const enum Sections {
  FeaturedBanner = "featured_banner",
  TrendingCards = "trending_cards",
  Quests = "quests",
  Referral = "referral",
}

const mobile = useBreakpoints({ md: 765 }).smallerOrEqual("md");
const settingsStore = useSettingStore();
const { settings } = storeToRefs(settingsStore);

const order = computed(() => {
  if (settings.value.sections_order?.length) {
    return settings.value.sections_order;
  }
  return DefaultOrder;
});
</script>

<template>
  <Layout>
    <div class="pt-[47rem] px-5">
      <MarketBalance />
    </div>
    <div class="relative flex items-center justify-center">
      <StarBalance v-if="mobile" />
      <SpinAndWin v-if="mobile" />
    </div>
    <template v-for="(item, index) in order">
      <div class="relative pt-10">
        <FeaturedBannerPageSection v-if="item === Sections.FeaturedBanner" />
        <TrendingPageSection v-else-if="item === Sections.TrendingCards" />
        <ReferralPageSection v-else-if="item === Sections.Referral" />
        <QuestsPageSection v-else-if="item === Sections.Quests" />
        <CTAPageSection :name="item" v-else />
        <SpinAndWin v-if="index === 0" class="bottom-[10rem] hidden md:flex" />
      </div>
    </template>
  </Layout>
</template>
