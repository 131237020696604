import { defineStore } from "pinia";
import { ref } from "vue";
import { MissionApi } from "user/api/mission";
import container from "shared/inversify.config";
import {
  MissionFilter,
  MissionCategory,
  MissionClassName,
} from "user/models/mission";
import { Mission } from "user/models/mission";

export const useMissionStore = defineStore("mission", () => {
  const categories = ref<MissionCategory[]>([]);
  const available = ref<Mission[]>([]);
  const active = ref<Mission[]>([]);
  const complete = ref<Mission[]>([]);
  const referral = ref<Mission[]>([]);

  const missionsMap = ref<Record<number, Mission>>({});

  const selectedMission = ref<Mission>();

  function $reset() {
    available.value = [];
    active.value = [];
    complete.value = [];
    referral.value = [];
  }

  function getMissionById(id: number) {
    return missionsMap.value[id];
  }

  function getMissionByIdentifier(id: string) {
    return Object.values(missionsMap.value).find((el) => el.identifier === id);
  }

  async function getMission(id: number): Promise<Mission> {
    const mission = await container.get(MissionApi).getMissionById(id);

    return mission;
  }

  function getMissions(filter: MissionFilter): Mission[] | undefined {
    switch (filter) {
      case MissionFilter.Completed:
        return complete.value;
      case MissionFilter.Active:
        return active.value;
      default:
      case MissionFilter.Available:
        return available.value;
    }
  }

  async function fetchMissions() {
    const mp: Record<number, Mission> = {};
    const missions = await container.get(MissionApi).getMissions();
    const refMissions: Mission[] = [];

    active.value = missions.active.map((el) => {
      const m = { ...el, isActive: true };

      mp[m.id] = m;
      return m;
    });
    available.value = missions.available.map((el) => {
      const m = {
        ...el,
        isAvailable: true,
      };

      mp[m.id] = m;
      return m;
    });
    complete.value = missions.completed.map((el) => {
      const m = {
        ...el,
        isCompleted: true,
      };
      mp[m.id] = m;

      return m;
    });

    referral.value = refMissions;
    missionsMap.value = mp;
  }

  function setSelectedMission(mission: Mission) {
    selectedMission.value = mission;
  }

  function resetSelectedMission() {
    selectedMission.value = undefined;
  }

  function isStandardMission(mission: Mission) {
    return (
      mission.class_name === MissionClassName.Standard ||
      mission.class_name === null
    );
  }

  return {
    $reset,
    getMission,
    getMissions,
    getMissionById,
    getMissionByIdentifier,
    fetchMissions,
    setSelectedMission,
    resetSelectedMission,
    isStandardMission,
    categories: shallowReadonly(categories),
    getAvailable: shallowReadonly(available),
    getActive: shallowReadonly(active),
    getCompleted: shallowReadonly(complete),
    selectedMission: shallowReadonly(selectedMission),
    referral: shallowReadonly(referral),
    missionsMap: shallowReadonly(missionsMap),
  };
});
