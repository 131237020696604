import "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "user/pages/home.vue";
import Index from "user/pages/index.vue";

import AllMarket from "user/pages/market/allMarket.vue";
import MarketUgc from "user/pages/market/ugc.vue";
import MarketGems from "user/pages/market/gems.vue";
import MarketLootbox from "user/pages/market/lootbox.vue";

import BackpackOther from "user/pages/backpack/other.vue";
import UgcBackpack from "user/pages/backpack/ugcBackpack.vue";
import Profile from "user/pages/profile/index.vue";
import Login from "user/pages/login.vue";
import Scan from "user/pages/scan.vue";
import Spin from "user/pages/stw/SpinTheWheel.vue";

import Settings from "user/pages/settings/index.vue";
import Feedback from "user/pages/settings/feedback.vue";
import Privacy from "user/pages/settings/privacy.vue";
import Terms from "user/pages/settings/terms.vue";

import Backpack from "user/layouts/backpack.vue";
import Market from "user/layouts/market.vue";
import Missions from "user/layouts/missions.vue";

import { useSessionStore } from "user/stores/session";
import { useBoothStore } from "user/stores/booth";
import BoothQr from "user/pages/booth/booth-qr.vue";
import Tracking from "user/pages/referrals/Tracking.vue";
import ProfileReferrals from "user/pages/profile/ProfileReferrals.vue";
import BadgeBackpack from "user/pages/backpack/badgeBackpack.vue";
import Brand from "user/pages/brands/brand.vue";
import BuySpin from "user/pages/stw/BuySpin.vue";
import SpinTheWheel from "user/pages/stw/SpinTheWheel.vue";

const routes = [
  { path: "/", component: Index, name: "index" },
  { path: "/login", name: "login", component: Login },
  { path: "/home", component: HomeView, name: "home" },
  {
    path: "/market",
    name: "market",
    component: Market,
    children: [
      { path: "/market/all", name: "market-all", component: AllMarket },
      { path: "/market/ugc", name: "market-ugc", component: MarketUgc },
      { path: "/market/gems", name: "market-gems", component: MarketGems },
      {
        path: "/market/lootbox",
        name: "market-lootbox",
        component: MarketLootbox,
      },
    ],
  },
  {
    path: "/missions/:type/:status",
    component: Missions,
    name: "missions",
  },
  { path: "/brand/:id", component: Brand, name: "brand" },
  {
    path: "/backpack",
    component: Backpack,
    name: "backpack",
    children: [
      { path: "/backpack/ugc", name: "backpack-ugc", component: UgcBackpack },
      {
        path: "/backpack/badge",
        name: "backpack-badge",
        component: BadgeBackpack,
      },
      {
        path: "/backpack/other",
        name: "backpack-other",
        component: BackpackOther,
      },
    ],
  },
  {
    path: "/booth/:token",
    component: BoothQr,
    name: "booth-reward",
  },
  { path: "/profile", component: Profile, name: "profile" },
  { path: "/scan", component: Scan },
  { path: "/spin-the-wheel", component: SpinTheWheel, name: "spin-the-wheel" },
  { path: "/spin-the-wheel/buy", component: BuySpin, name: "buy-spin" },
  { path: "/spin-the-wheel/:oboarding", component: Spin },
  { path: "/settings", component: Settings, name: "settings" },
  { path: "/settings/feedback", component: Feedback, name: "feedback" },
  {
    path: "/profile/referrals",
    component: ProfileReferrals,
    name: "profile-referrals",
  },
  { path: "/settings/privacy", component: Privacy, name: "privacy" },
  { path: "/settings/terms", component: Terms, name: "terms" },
  {
    path: "/profile/referrals/tracking",
    component: Tracking,
    name: "profile-referrals-tracking",
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from) => {
  const session = useSessionStore();
  const booths = useBoothStore();
  let urlParams = new URLSearchParams(window.location.search);
  const booth_token = urlParams.get("booth_token");

  if (
    (session.currentUser == null || session.isAnonymous) &&
    to.name !== from.name
  ) {
    await session.tryFetchCurrentUser();
  }
  if (booth_token != null) {
    booths.setPendingBooth(booth_token);
  }
  if (session.isAnonymous && to.path == "/login") {
    return;
  }
  if ((session.currentUser == null || session.isAnonymous) && to.path === "/") {
    return { path: "/login" };
  }
  if (session.currentUser != null && to.path === "/") {
    return { path: "home" };
  }
});

export default router;
