let images: HTMLImageElement[] = [];

export function prefetchImages(urls: string[]) {
  // this is a replacement for <link rel="prefetch" ..> that works in all browsers
  // thanks Safari

  // NOTE that we need to keep the prefetched URLs in memory
  //  or else this doesn't do much
  images = [];

  for (const url of urls) {
    const image = new Image();
    image.src = url;

    images.push(image);
  }
}
