import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import { Mission, MissionClassName } from "user/models/mission";
import { useNotificationStore } from "user/stores/notifications";

export const useHandleMission = () => {
  async function startMission(mission: Mission) {
    try {
      const res = await container.get(MissionApi).startMission(mission.id);

      return res;
    } catch (error) {
      throw error;
    }
  }

  async function completeMission(mission: Mission) {
    if (mission.class_name === MissionClassName.RobloxUpdate) {
      try {
        const res = await container
          .get(MissionApi)
          .completeRobloxMission(mission.id);

        useNotificationStore().fetchNotifications();

        return res;
      } catch (error) {
        throw error;
      }
    }

    if (mission.class_name === MissionClassName.ReferralEvent) {
      try {
        const res = await container
          .get(MissionApi)
          .claimReferralMission(mission.id);

        useNotificationStore().fetchNotifications();

        return res;
      } catch (error) {
        throw error;
      }
    }
  }

  return { startMission, completeMission };
};
