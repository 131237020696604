<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Reward, isGems } from "user/models/items";
import { useBuyStore } from "user/stores/buy";
import { Reciept, useMarketStore } from "user/stores/marketplace";
import { useSessionStore } from "user/stores/session";

const router = useRouter();
const buyStore = useBuyStore();

const { selectedItem } = storeToRefs(buyStore);

const purchaseSuccess = ref(false);
const insufficientStars = ref(false);
const insufficientGems = ref(false);
const signupReminder = ref(false);
const gemSelected = ref(false);
const itemDialogOpen = ref(false);

const marketStore = useMarketStore();
const sessionStore = useSessionStore();

async function purchaseItem(item: Reward) {
  if (gemSelected.value) {
    //check to see if the user has enough stars
    if (
      (sessionStore.currentUser?.currencies.stars ?? 0) < item.price?.stars!
    ) {
      itemDialogOpen.value = false;
      insufficientStars.value = true;
      return;
    }
  } else {
    if ((sessionStore.currentUser?.currencies.gems ?? 0) < item.price?.gems!) {
      itemDialogOpen.value = false;
      insufficientGems.value = true;
      return;
    }
  }
  // otherwise check if they have enough gems

  const response = await marketStore.buyItem(item.id);
  itemDialogOpen.value = false;
  // wait for slide up to close
  setTimeout(() => {
    if ((response as Reciept)?.status) {
      purchaseSuccess.value = true;
    } else {
      // TODO NOT ALWAYS THE CASE
      insufficientGems.value = true;
      insufficientStars.value = true;
    }
  }, 300);
}

function closeInsufficientDialog() {
  insufficientGems.value = false;
  insufficientStars.value = false;
}

function handleMarket() {
  purchaseSuccess.value = false;
  buyStore.$reset();
  router.push({ name: "market-all", replace: true });
}

function handleBackpackUgc() {
  purchaseSuccess.value = false;
  buyStore.$reset();
  router.push({ name: "backpack-ugc", replace: true });
}

function handleBuyAgain() {
  purchaseSuccess.value = false;
  buyStore.$reset();
  router.push({ name: "buy-spin", replace: true });
}

function handleHome() {
  purchaseSuccess.value = false;
  buyStore.$reset();
  router.push({ name: "home", replace: true });
}

watch(selectedItem, () => {
  itemDialogOpen.value = selectedItem !== null;
  if (selectedItem.value && isGems(selectedItem.value)) {
    gemSelected.value = true;
  }
});
</script>

<template>
  <template v-if="selectedItem">
    <MarketItemSlideUp
      v-model:open="itemDialogOpen"
      @update:open="buyStore.$reset()"
      :item="selectedItem"
      :gems="gemSelected"
      @confirm="purchaseItem(selectedItem)"
    />
    <ReceiveModalLayout
      v-if="purchaseSuccess"
      v-model:open="purchaseSuccess"
      :prize="selectedItem"
      :price="selectedItem.price"
      @go-to-backpack="handleBackpackUgc"
      @go-to-home="handleHome"
      @go-to-market="handleMarket"
      @buy-again="handleBuyAgain"
      is-market
    />
    <SignupReminderDialog
      v-model:open="signupReminder"
      @confirm="router.push({ name: 'login' })"
    />
    <InsufficientStarsDialog
      :open="insufficientStars"
      @close="closeInsufficientDialog()"
      @spin="
        () => {
          closeInsufficientDialog();
          router.push({ name: 'spin-the-wheel' });
        }
      "
      @missions="
        () => {
          closeInsufficientDialog();
          router.push({
            name: 'missions',
            params: { status: 'available', type: 'missions' },
          });
        }
      "
    />
    <InsufficientGemsDialog
      :open="insufficientGems"
      @close="closeInsufficientDialog()"
      @market="
        () => {
          closeInsufficientDialog();
          router.push({ name: 'market-gems' });
        }
      "
      @keep-browsing="closeInsufficientDialog()"
    />
  </template>
</template>
