<script setup lang="ts">
import { useRouter } from "vue-router";

const router = useRouter();
</script>

<template>
  <Layout type="stw">
    <div class="z-20 flex justify-center flex-col pt-[47rem] px-5">
      <MarketBalance />
    </div>

    <div
      class="bg-buy-spin-switcher relative z-20 font-bold w-fit text-[14rem]/[24rem] md:text-[24rem]/[34rem] flex p-1 mx-auto rounded-ml mt-4"
    >
      <button
        class="rounded-ml py-2 px-4 md:px-4 lg:px-20 cursor-pointer"
        :class="{
          'bg-buy-spin-switcher-btn text-white':
            router.currentRoute.value.name === 'spin-the-wheel',
          'text-[#9EA1A1]': router.currentRoute.value.name !== 'spin-the-wheel',
        }"
        @click="router.push({ name: 'spin-the-wheel' })"
      >
        SPIN & WIN
      </button>
      <button
        class="rounded-ml py-2 px-4 md:px-4 lg:px-20 cursor-pointer"
        :class="{
          'bg-buy-spin-switcher-btn text-white':
            router.currentRoute.value.name === 'buy-spin',
          'text-[#9EA1A1]': router.currentRoute.value.name !== 'buy-spin',
        }"
        @click="router.push({ name: 'buy-spin' })"
      >
        BUY SPINS
      </button>
    </div>

    <slot />
  </Layout>
</template>
