<script setup lang="ts">
import { computed } from "vue";
import { useMarketStore } from "user/stores/marketplace";

const rewardsStore = useMarketStore();
const rewards = computed(() => rewardsStore.gems);
</script>

<template>
  <MarketGrid :items="rewards" gems />
</template>
