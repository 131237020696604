<script setup lang="ts">
import playsideHeader from "user/assets/playside-header.svg";
import desktopHome from "user/assets/icons/navDesktop/home_desktop_active.svg";
import desktopMissions from "user/assets/icons/navDesktop/missions_desktop_active.svg";
import desktopBackpack from "user/assets/icons/navDesktop/backpack_desktop_active.svg";
import desktopProfile from "user/assets/icons/navDesktop/profile_desktop_active.svg";
import desktopMarket from "user/assets/icons/navDesktop/market_desktop_active.svg";
import desktopInactiveHome from "user/assets/icons/navDesktop/home_desktop_inactive.svg";
import desktopInactiveMarket from "user/assets/icons/navDesktop/market_desktop_inactive.svg";
import desktopInactiveMissions from "user/assets/icons/navDesktop/mission_desktop_inactive.svg";
import desktopInactiveBackpack from "user/assets/icons/navDesktop/backpack_desktop_inactive.svg";
import desktopInactiveProfile from "user/assets/icons/navDesktop/profile_desktop_inactive.svg";
import { useSessionStore } from "user/stores/session";
import { useBreakpoints } from "@vueuse/core";

defineProps<{
  floating?: boolean;
}>();
const router = useRouter();

const mobile = useBreakpoints({ md: 765 }).smallerOrEqual("md");
const sessionStore = useSessionStore();

const isAnonymous = computed(() => sessionStore.isAnonymous);

const signUpReminderOpen = ref(false);

function openSignupModal() {
  signUpReminderOpen.value = true;
}
async function login() {
  router.push({ name: "login" });
}
</script>

<template>
  <div v-if="!mobile">
    <nav
      class="fixed bottom-0 left-0 z-20 h-full max-w-[260rem] lg:max-w-[414rem] flex flex-col gap-x-4 bg-[#344B9D] bg-opacity-50 py-2.5 drop-shadow-[0px_-4px_15px_rgba(108,118,175,0.25)] px-[60rem]"
    >
      <img
        :src="playsideHeader"
        class="pt-52 mt-[45rem] cursor-pointer"
        @click="router.push({ name: 'home' })"
      />
      <StarBalance class="self-center mt-[10rem]" />

      <NavBarItem
        to="/home"
        label="Home"
        :image="desktopHome"
        :inactive-image="desktopInactiveHome"
        :anon-user="isAnonymous"
      />
      <NavBarItem
        to="/market/all"
        label="Market"
        :image="desktopMarket"
        @block="openSignupModal"
        block
        :inactive-image="desktopInactiveMarket"
        :anon-user="isAnonymous"
      />
      <NavBarItem
        to="/missions/missions/available"
        label="Quests"
        :image="desktopMissions"
        :inactive-image="desktopInactiveMissions"
        :anon-user="isAnonymous"
      />

      <!-- <div class="relative flex flex-1 items-center justify-center self-center">
        <RouterLink
          to="/scan"
          exact-active-class="!opacity-100"
          class="pointer-events-auto"
        >
          <img :src="scan" alt="" class="h-[52rem]" />
        </RouterLink>
      </div> -->

      <NavBarItem
        to="/backpack/ugc"
        label="Backpack"
        :image="desktopBackpack"
        :inactive-image="desktopInactiveBackpack"
        :anon-user="isAnonymous"
      />

      <NavBarItem
        to="/profile"
        label="Profile"
        :image="desktopProfile"
        :inactive-image="desktopInactiveProfile"
        :anon-user="isAnonymous"
        @block="openSignupModal"
        block
      />
      <NavFooter />
    </nav>
    <SignupReminderDialog v-model:open="signUpReminderOpen" @confirm="login" />
  </div>
</template>
