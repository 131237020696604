<script setup lang="ts">
import BearFloatingIcon from "user/assets/bear-floating.gif";
import { useReferralStore } from "user/stores/referral";

const referralStore = useReferralStore();

onMounted(async () => {
  referralStore.get();
});
</script>

<template>
  <Layout class="flex-col flex bg-white">
    <HeaderReferrals class="shadow-lg">Track Invites</HeaderReferrals>
    <div
      v-if="referralStore.referrals?.length"
      class="flex flex-col pt-[34rem] px-7"
    >
      <div
        v-for="referral in referralStore.referrals"
        key="referral"
        class="flex flex-col"
      >
        <span class="text-[18rem]/[22rem] font-medium">{{
          referral.name
        }}</span>
        <span class="text-[#636363] text-[15rem]/[17rem]"
          >Signed up on {{ new Date(referral.signup_at) }}</span
        >
      </div>
    </div>
    <div v-else class="flex flex-col items-center justify-center grow">
      <img
        :src="BearFloatingIcon"
        alt="Empty"
        class="w-[100rem] object-contain"
      />
      <span class="text-[16rem]/[22rem]">No referrals yet</span>
    </div>
  </Layout>
</template>
