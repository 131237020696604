<template>
  <Layout class="flex-col flex">
    <div class="hidden md:block">
      <ProfileHeader />
    </div>
    <HeaderReferrals>Referrals</HeaderReferrals>
    <div class="pb-[55rem]">
      <ReferralsWidget />
    </div>
  </Layout>
</template>
