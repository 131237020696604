import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { BackpackApi } from "user/api/backpack";
import { Reward } from "user/models/items";

export const useBackpackStore = defineStore("backpack", () => {
  const ugc = ref<Reward[]>();
  const others = ref<Reward[]>();
  const badges = ref<Reward[]>();
  const prizeCount = ref<Number>(0);

  function $reset() {
    others.value = undefined;
    ugc.value = undefined;
  }

  async function countPrizes() {
    await fetchBackpack();

    let _prizeCount = 0;

    for (const prize of ugc.value!) {
      if (prize.quantity != null) {
        _prizeCount += prize.quantity;
      } else {
        _prizeCount += 1;
      }
    }

    for (const prize of others.value!) {
      if (prize.quantity != null) {
        _prizeCount += prize.quantity;
      } else {
        _prizeCount += 1;
      }
    }

    prizeCount.value = _prizeCount;
  }

  async function fetchBackpack() {
    const result = await container.get(BackpackApi).getBackpack();

    ugc.value = result.ugcs;
    others.value = result.other;
    badges.value = result.badges ?? [];
  }

  return {
    $reset,
    ugc: shallowReadonly(ugc),
    others: shallowReadonly(others),
    badges: shallowReadonly(badges),
    fetchBackpack,
    countPrizes,
    totalPrizeCount: shallowReadonly(prizeCount),
  };
});
