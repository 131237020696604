<script setup lang="ts">
import StarsIcon from "user/assets/STARs.png";
import ShareIcon from "user/assets/Share.png";
import CopyIcon from "user/assets/icons/Copy-filled.png";
import GiftIcon from "user/assets/Gift.png";
import TrackIcon from "user/assets/Track.png";
import Jojo from "user/assets/jojo-referrals.png";
import JojoTiger from "user/assets/jojo-tiger-referral.png";
import { useSessionStore } from "user/stores/session";
import { useReferralStore } from "user/stores/referral";

const sessionStore = useSessionStore();
const referralStore = useReferralStore();

const router = useRouter();
const code = ref(sessionStore.currentUser?.referral_code);
const starsEarned = ref(0);

const isOpenSlideUp = ref(false);

async function handleCopyClipboard() {
  if (!code.value) {
    return;
  }
  await navigator.clipboard.writeText(code.value);
}

function handleEnterCode() {
  isOpenSlideUp.value = true;
}

function handleTracking() {
  router.push({ name: "profile-referrals-tracking" });
}

function handleShare() {
  const shareData = {
    title:
      "Join your friends on PLAYSIDE! Claim FREE UGCs & IRL Rewards by completing quests & collecting STARs!",
    text: `Use my referral code for bonus 100 STARs: ${code.value}`,
    url: "/",
  };
  try {
    if (!navigator.canShare(shareData)) {
      return;
    }
    if (navigator.share) {
      navigator
        .share(shareData)
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    }
  } catch (error) {
    console.log("Error:", error);
  }
}

function handleClosePopup() {
  isOpenSlideUp.value = false;
}

onMounted(async () => {
  referralStore.get();
});
</script>

<template>
  <div class="relative text-black grow flex flex-col w-full h-full">
    <div
      class="bg-home md:bg-none relative bg-cover flex flex-col gap-8 pt-16 md:pt-0 grow px-5"
    >
      <div
        class="h-3/5 bg-gradient-to-t from-white to-[#D9D9D900] left-0 w-full absolute bottom-0"
      />
      <h1
        class="text-[28rem]/[32rem] font-semibold text-white text-center md:text-black"
      >
        Invite Friends: <br />
        The More, The Merrier
      </h1>
      <div class="bg-aquaToPurple rounded-[10rem] p-1 mx-1">
        <div
          class="flex text-white bg-[#020053] relative py-[20rem] px-[50rem] gap-4 rounded-[10rem]"
        >
          <img
            :src="JojoTiger"
            alt="jojo-tiger"
            class="absolute -left-[24rem] -top-1/4 h-[88rem]"
          />
          <img
            :src="Jojo"
            alt="jojo"
            class="absolute -right-[24rem] -bottom-1/3 h-[90rem]"
          />
          <div class="flex flex-col basis-[calc(50%-8rem)] items-center">
            <div class="text-[12rem]/[24rem]">Friends invited</div>
            <span
              class="text-transparent bg-aquaToPurple bg-clip-text text-[40rem] font-semibold leading-normal"
              >{{ referralStore.referrals?.length ?? 0 }}</span
            >
          </div>
          <div class="flex flex-col basis-[calc(50%-8rem)] items-center">
            <div class="text-[12rem]/[24rem]">STARs earned</div>
            <div class="flex items-center gap-1">
              <span
                class="text-transparent bg-aquaToPurple bg-clip-text text-[40rem] font-semibold leading-normal"
                >{{ starsEarned }}</span
              >
              <img
                :src="StarsIcon"
                alt="Starts"
                class="w-[36rem] h-[36rem] object-contain"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex gap-2 z-10 relative">
        <div class="relative flex w-full" @click="handleCopyClipboard">
          <FunInput id="code" v-model="code" placeholder="CODE0456" readonly />
          <img
            :src="CopyIcon"
            alt="Copy"
            class="w-[22rem] h-[22rem] absolute object-contain right-2 top-1/2 -translate-y-1/2"
          />
        </div>
        <button
          class="w-[54rem] h-[54rem] bg-jadeMountain flex items-center justify-center rounded-md"
          @click="handleShare"
        >
          <img :src="ShareIcon" alt="Share" class="object-contain" />
        </button>
      </div>

      <div class="flex flex-col gap-2 z-10 relative">
        <ButtonClient @click="handleEnterCode">
          <img
            :src="GiftIcon"
            alt="Share"
            class="object-contain h-[24rem] w-[24rem]"
          />
          <span class="text-[20rem]/[20rem]">Enter Referral Code</span>
        </ButtonClient>
        <ButtonClient @click="handleTracking">
          <img :src="TrackIcon" alt="Share" class="object-contain" />
          <span class="text-[20rem]/[20rem]"> Track Invites </span>
        </ButtonClient>
      </div>
    </div>
  </div>
  <ReferralItemSlideUp
    v-model:open="isOpenSlideUp"
    @confirm="handleClosePopup"
  />
</template>
