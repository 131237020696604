<script setup lang="ts">
// import { useBadgesStore } from "user/stores/badges";

import { useBackpackStore } from "user/stores/backpack";

const ItemStore = useBackpackStore();

const items = computed(() => ItemStore.others);

onMounted(async () => {
  await ItemStore.fetchBackpack();
});
</script>

<template>
  <BackpackGrid v-if="items" :items="items" />
</template>
