import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { BrandApi } from "user/api/brand";
import { UserBrand as Brand } from "user/models/brand";
import { BoothApi } from "user/api/booth";

export const useBoothStore = defineStore("booth", () => {
    const pendingBooth = ref<string>();
    const ready = ref<boolean>();

    function $reset() {
        pendingBooth.value = undefined;
    }

    function setPendingBooth(booth_token: string) {
        if (pendingBooth.value != booth_token) {
            pendingBooth.value = booth_token;
            ready.value = true
        }
    }

    async function triggerBooth() {
        if(ready.value && pendingBooth.value != null) {
            await container.get(BoothApi).boothCompletion(pendingBooth.value);
            ready.value = false;
        }
    }

    return {
        $reset,
        setPendingBooth,
        triggerBooth,
    };
});
