export enum CurrencyKind {
  GEMs = "gems",
  STARs = "stars",
}

export const enum TrendingCardType {
  Brands = "brands",
  Missions = "missions",
}

export const enum ReferralRewardType {
  Stars = "stars",
  Gems = "gems",
}

export interface Trending {
  trending_card_type: TrendingCardType;
  trending_card_id: number | undefined;
  id?: number | undefined;
}


export interface IFeaturedBanner {
  id?: number;
  featured_banner_brand_id: number | null;
  featured_banner_image: string;
  featured_banner_image_url: string;
  featured_banner_url: string;
  isBrandInvolved: boolean;
}

export interface IPromoOverlay {
  id?: number;
  promo_btn_color: string;
  promo_btn_text: string;
  promo_btn_url: string;
  promo_repeat_interval: number;
  overlay_image: string;
}

export interface ICustomCta {
  id?: number;
  custom_cta_brand_id: number | undefined;
  custom_cta_header: string;
  custom_cta_image: string;
  custom_cta_image_url: string;
  custom_cta_card_link: string;
}

export enum SectionTypeEnum {
  FeaturedBanner = "featured_banner",
  TrendingCards = "trending_cards",
  Quests = "quests",
  Referral = "referral"
}

export interface GlobalSettings {
  sections_order: string[];
  referral_reward_type: CurrencyKind;
  referral_reward_allocation: number;

  //ad section
  ad_poster_image?: string;
  ad_button_color?: string;
  ad_button_text?: string;
  ad_button_link?: string;
  ad_repeat_interval_hours?: number;
  promo_overlay: IPromoOverlay[];
  featured_banners: IFeaturedBanner[];
  trending_cards: Trending[];
  custom_cta: ICustomCta[];
}
