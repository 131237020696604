<script lang="ts" setup>
const route = useRoute();
const router = useRouter();

const types = {
  brands: "Brands",
  missions: "Quests",
  rewards: "Rewards",
};
</script>

<template>
  <div class="w-full overflow-x-hidden">
    <div
      class="no-scrollbar flex justify-between md:justify-start gap-2 overflow-x-auto px-5"
    >
      <div
        v-for="(value, key) in types"
        :key="key"
        :class="[
          'cursor-pointer rounded-full border px-4 py-0 font-semibold text-base',
          key === route.params.type
            ? 'bg-black text-white'
            : 'bg-white text-black',
        ]"
        @click="
          router.push({
            name: 'missions',
            params: { status: route.params.status, type: key },
          })
        "
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>
