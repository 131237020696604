import { injectable } from "inversify";
import container from "shared/inversify.config";
import { Api } from "shared/api/index";
import type { Reward } from "user/models/items";

interface MarketApiReturn {
  ugcs: Reward[];
  all: Reward[];
  gems: Reward[];
  lootboxes: Reward[];
}

@injectable()
export class MarketApi {
  axios = container.get(Api);

  async getMarketplace(): Promise<MarketApiReturn> {
    const { data } = await this.axios.get<{ data: MarketApiReturn }>("/market");
    return data;
  }

  async purchase(reward_id: number): Promise<{}> {
    const { data } = await this.axios.post<{ data: {} }>("/purchase", {
      reward_id,
    });
    return data;
  }

  async openLootbox(reward_id: number): Promise<Reward> {
    const { data } = await this.axios.post<{ data: Reward }>("/lootbox", {
      lootbox_id: reward_id,
    });
    return data;
  }
}
