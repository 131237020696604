import "./style.css";
import { createApp } from "vue";
import { DotLottiePlayer } from "@aarsteinmedia/dotlottie-player";
import Router from "user/routes";
import App from "user/app.vue";

const app = createApp(App);

// Pinia + Axios setup
import { createPinia } from "pinia";
const Pinia = createPinia();

// I18n loader

app
  .use(Router)
  .use(Pinia)
  .component("DotLottiePlayer", DotLottiePlayer)
  .provide("axios", app.config.globalProperties.axios)
  .mount("#app");
