<template>
  <Layout>
    <HeaderBar />

    <div class="flex flex-col px-5">
      <div class="z-20 flex justify-center flex-col">
        <div class="text-Black text-center align-middle text-3xl font-semibold">
          The Market
        </div>
        <MarketBalance />
      </div>
      <TabBar class="left-0 top-0 z-10 mt-6 overflow-x-visible">
        <TabBarItem to="/market/all" label="All Items" backpack />
        <TabBarItem to="/market/ugc" label="UGCs" backpack />
        <TabBarItem to="/market/lootbox" label="Lootboxes" backpack />
        <TabBarItem to="/market/gems" label="GEMs" backpack />
        <!-- <TabBarItem to="/market/lootbox" label="Lootbox" /> -->
      </TabBar>

      <RouterView />
    </div>
  </Layout>
</template>
