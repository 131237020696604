import container from "shared/inversify.config";

import { computedEager } from "@vueuse/core";
import { defineStore } from "pinia";
import { SessionApi } from "user/api/session";
import { User, UserKind } from "user/models/user";
import { useBackpackStore } from "user/stores/backpack";
import { useMissionStore } from "user/stores/mission";
import { useMarketStore } from "user/stores/marketplace";
import { useBrandStore } from "user/stores/brand";

export const useSessionStore = defineStore("session", () => {
  const missionStore = useMissionStore();
  const rewardsStore = useMarketStore();
  const backpackStore = useBackpackStore();
  const brandStore = useBrandStore();
  const starsBalance = reactive({
    stars: 0,
    position: 0,
    totalStars: 0,
  });

  const currentUser = ref<User | null | undefined>();

  const canFetch = ref(true);

  function $reset() {
    currentUser.value = undefined;
  }

  async function prefetchDataForUser() {
    // fetch the minimum we need for the home page in the foreground
    try {
      await Promise.all([
        missionStore.fetchMissions(),
        rewardsStore.fetchMarketplace(),
        backpackStore.fetchBackpack(),
        brandStore.fetchBrands(),
      ]);
    } catch (err) {
      console.log(err);
    }

    canFetch.value = false;
    setTimeout(() => (canFetch.value = true), 10000);
    // and any additional data for the first pages of other tabs
    // in the background
  }

  async function tryFetchCurrentUser() {
    try {
      currentUser.value = await container.get(SessionApi).getCurrentSession();
      if (canFetch.value) {
        await prefetchDataForUser();
      }
    } catch {
      // if this fails for any reason, unset the current user
      currentUser.value = null;
    }
  }

  const isAnonymous = computedEager(
    () => currentUser.value?.kind == UserKind.Anonymous,
  );

  async function destroySession() {
    await container.get(SessionApi).destroyCurrentSession();
    $reset();
  }

  function deleteCookie() {
    const cookies = document.cookie.split(";");
    cookies.forEach((cookie) => {
      const cookieName = cookie.split("=")[0];
      document.cookie =
        cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    });
  }

  return {
    $reset,
    tryFetchCurrentUser,
    currentUser: shallowReadonly(currentUser),
    isAnonymous,
    starsBalance,
    destroySession,
    deleteCookie,
  };
});
