<script setup lang="ts">
import ProfileAchievementTile from "user/components/ProfileAchievementTile.vue";
import starTeal from "user/assets/star-teal.png";
import starGrey from "user/assets/star-grey.png";
import { useSessionStore } from "user/stores/session";
import { computed } from "vue";
import { useBackpackStore } from "user/stores/backpack";
import { useIntervalFn } from "@vueuse/core";

const backpackStore = useBackpackStore();
const sessionStore = useSessionStore();
const user = computed(() => sessionStore.currentUser);
const missions_completed = computed(() => user.value?.missions_completed);
const prizes_earned = computed(() => backpackStore.totalPrizeCount ?? 0);

useIntervalFn(
  () => {
    void sessionStore.tryFetchCurrentUser();
    void backpackStore.countPrizes();
  },
  5000,
  {
    immediateCallback: true,
  },
);

onMounted(async () => {
  await backpackStore.countPrizes();
  await sessionStore.tryFetchCurrentUser();
});
</script>

<template>
  <Layout>
    <ProfileHeader />
    <!-- edit profile goes here -->
    <div class="mt-[18rem] w-full px-5 pt-6">
      <div class="mb-[5rem] text-[20rem]/[28rem] font-semibold text-black">
        Achievements
      </div>

      <!-- Achievement Tiles -->
      <div class="grid grid-cols-2 gap-[15rem]">
        <ProfileAchievementTile
          title="Prizes Claimed"
          :value="prizes_earned.valueOf()"
          :graphic="starGrey"
        />

        <ProfileAchievementTile
          title="Quests Completed"
          :value="missions_completed ?? 0"
          :graphic="starTeal"
        />
      </div>
      <!-- Auths -->
    </div>
    <div
      class="mb-[5rem] text-[20rem]/[28rem] font-semibold text-black mt-[18rem] w-full px-5 pt-6"
    >
      Connect Social
    </div>
    <ProfileOauth />
  </Layout>
</template>
