<script setup lang="ts">
import container from "shared/inversify.config";
import { MerchApi } from "user/api/merch";
import {
  isLootBox,
  isMerch,
  isSpins,
  isUGC,
  Reward,
} from "user/models/items.js";

const props = defineProps<{
  items: Reward[];
  ugc?: boolean;
}>();

const router = useRouter();

const selectedItem = ref<Reward>();
const selectedItemIndex = ref<number>(-1);
const badgeDialogOpen = ref(false);
const openLootboxModal = ref(false);
const usernameForMerch = ref<string>("");
const isErrorForMerch = ref<boolean>(false);

// fill the rest of the page with placeholders
const placeholders = computed(() =>
  12 - props.items.length > 0 ? 12 - props.items.length : 0,
);

function openDialog(item?: Reward, index?: number) {
  if (item != null) {
    if (index != null) {
      selectedItemIndex.value = index;
    }
    if (item != null) {
      selectedItem.value = item;
    }
    badgeDialogOpen.value = true;
  } else {
    selectedItemIndex.value = props.items.length;
  }
}

async function openAction() {
  if (selectedItem.value) {
    if (isMerch(selectedItem.value)) {
      try {
        const res = await container
          .get(MerchApi)
          .redeemMerch(selectedItem.value.id, usernameForMerch.value);

        if (res && res.success) {
          badgeDialogOpen.value = false;
          usernameForMerch.value = "";
        }
      } catch (error) {
        isErrorForMerch.value = true;

        setTimeout(() => {
          isErrorForMerch.value = false;
        }, 2000);
      }
      return;
    } else if (isSpins(selectedItem.value)) {
      badgeDialogOpen.value = false;
      router.push({ name: "spin-the-wheel" });
    } else if (isLootBox(selectedItem.value)) {
      // do lootbox opening
      badgeDialogOpen.value = false;
      openLootboxModal.value = true;
    } else if (isUGC(selectedItem.value)) {
      // open roblox link
      badgeDialogOpen.value = false;
      window.open(
        "https://www.roblox.com/games/17216786206/PLAYGROUND-AFK-for-UGC",
        "_blank",
      );
    } else {
      badgeDialogOpen.value = false;
    }
  }
}

function handleMarket() {
  badgeDialogOpen.value = false;
  selectedItem.value = undefined;
  router.push({ name: "market-all", replace: true });
}

function handleBackpackUgc() {
  badgeDialogOpen.value = false;
  selectedItem.value = undefined;
  router.push({ name: "backpack-ugc", replace: true });
}

function handleHome() {
  badgeDialogOpen.value = false;
  selectedItem.value = undefined;
  router.push({ name: "home", replace: true });
}
</script>

<template>
  <div
    class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-x-2 gap-y-4 px-1 py-6"
  >
    <BackpackListCard
      v-for="(item, index) of items"
      :key="index"
      :item="item"
      :selected="index === selectedItemIndex"
      @click="openDialog(item, index)"
    />

    <ListCardPlaceholder v-for="(_, index) of placeholders" :key="index" />

    <template v-if="badgeDialogOpen && selectedItem">
      <BackpackSlideUp
        v-model:open="badgeDialogOpen"
        @update:open="selectedItem = undefined"
        @confirm="openAction"
        :item="selectedItem"
        v-model:username="usernameForMerch"
        :is-error-for-merch="isErrorForMerch"
      />
    </template>

    <div v-if="selectedItem">
      <ReceiveModalLayout
        v-model:open="openLootboxModal"
        :prize="selectedItem"
        @go-to-backpack="handleBackpackUgc"
        @go-to-home="handleHome"
        @go-to-market="handleMarket"
        is-market
      />
    </div>
  </div>
</template>
