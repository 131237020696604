<script setup lang="ts">
import { useVModel } from "@vueuse/core";
import type { ClassNameValue } from "tailwind-merge";

const props = withDefaults(
  defineProps<{
    label?: string;
    placeholder: string;
    id: string;
    required?: boolean;
    type?: string;
    autocomplete?: string;
    inputClass?: ClassNameValue;
    modelValue?: string;
    readonly?: boolean;
    error?: boolean;
  }>(),
  {
    type: "text",
  },
);

defineOptions({
  inheritAttrs: false,
});

const emit = defineEmits<{
  "update:modelValue": [value: string];
}>();

const modelValue = useVModel(props, "modelValue", emit);
</script>

<template>
  <div class="flex flex-col gap-y-1 w-full">
    <label v-if="label" :for="id" class="text-[13rem]/[17rem] text-black">
      {{ label }}
    </label>

    <input
      :id="id"
      v-model="modelValue"
      :type="type"
      :required="required"
      :autocomplete="autocomplete"
      class="h-[54rem] rounded px-4 py-3 w-full text-[15rem]/[22rem] text-coated shadow-home placeholder-milestone outline-none ring-0"
      :class="{ inputClass, 'text-red border-red border-[2rem]': error }"
      :placeholder="placeholder"
      :readonly="readonly"
      v-bind="$attrs"
    />
  </div>
</template>
