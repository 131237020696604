import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { Rarity, Reward } from "user/models/items";
import { SpinApi } from "user/api/spin";
import { SpinReward } from "user/models/spin";
import { isLootBox } from "user/models/items";
import { isStars } from "user/models/items";
import { isBadge } from "user/models/items";
import { isUGC } from "user/models/items";
import { OnboardingReward } from "user/models/initial-rewards";
import { useSessionStore } from "./session";

export const useSpinStore = defineStore("spin", () => {
  const wheelspinReward = ref<Reward | OnboardingReward>();
  const session = useSessionStore();

  const wheelspinDisplay = computed(() => {
    if (wheelspinReward.value?.class_name != null) {
      if (wheelspinReward.value.is_mystery_box_reward) {
        return SpinReward.Mystery;
      }
      if (isStars(wheelspinReward.value)) {
        return (wheelspinReward.value.reward?.stars ?? 1) > 99
          ? SpinReward.StarsHigh
          : SpinReward.StarsLow;
      }
      if (isLootBox(wheelspinReward.value)) {
        if (
          wheelspinReward.value.rarity == Rarity.stars ||
          wheelspinReward.value.identifier.includes("stars")
        ) {
          return SpinReward.StarsLootbox;
        } else {
          return SpinReward.UgcLootbox;
        }
      }
      if (isBadge(wheelspinReward.value)) {
        return SpinReward.Legendary;
      }
      if (isUGC(wheelspinReward.value)) {
        return SpinReward.Ugc;
      }
      return SpinReward.Mystery;
    } else {
      return SpinReward.Robux;
    }
  });

  function $reset() {
    wheelspinReward.value = undefined;
  }

  async function spinWheel() {
    const result = await container.get(SpinApi).spinwheel();
    wheelspinReward.value = result;
    session.tryFetchCurrentUser();
    console.log(
      "🚀 ~ spinWheel ~ wheelspinReward.value:",
      wheelspinReward.value,
    );
  }

  function setOnboardingSpin(reward: Reward | OnboardingReward) {
    wheelspinReward.value = reward;
  }

  return {
    $reset,
    wheelspinDisplay: shallowReadonly(wheelspinDisplay),
    wheelspinReward: shallowReadonly(wheelspinReward),
    setOnboardingSpin,
    spinWheel,
  };
});
