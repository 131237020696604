<script setup lang="ts">
import Modal from "user/components/Modal.vue";
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import Star from "user/assets/STARs.png";

defineEmits<{
  missions: [];
  spin: [];
}>();

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <Modal v-model:open="open">
    <div
      class="flex flex-col items-center p-[50rem] text-center justify-between gap-y-3"
    >
      <div class="text-[30rem] font-semibold">OH WAIT!</div>
      <div class="line- text-[20rem] font-medium">
        You don't have enough STARs.
      </div>
      <div class="flex justify-center">
        <img :src="Star" alt="STARs" class="object-contain w-2/3" />
      </div>
      <FlatButton
        label="Go to Quests"
        :size="ButtonSize.md"
        hollow
        @click="$emit('missions')"
      />
      <FlatButton
        label="Spin the Wheel"
        :size="ButtonSize.md"
        @click="$emit('spin')"
      />
    </div>
  </Modal>
</template>
