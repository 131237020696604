<script setup lang="ts">
import { useBreakpoints } from "@vueuse/core";
import bg from "user/assets/home-bg.png";

const route = useRoute();
const mobile = useBreakpoints({ md: 765 }).smallerOrEqual("md");

const turnOnGalaxyBg = computed(() => {
  if (route.path.includes("home") || route.path.includes("profile")) {
    return true;
  }

  return false;
});
</script>

<template>
  <div class="fixed left-0 right-0 h-full w-full">
    <div class="relative h-full">
      <img
        :src="bg"
        class="absolute h-full z-[-1] w-full object-cover"
        v-if="turnOnGalaxyBg || (!turnOnGalaxyBg && !mobile)"
      />
      <div
        v-if="(mobile && turnOnGalaxyBg) || (!mobile && !turnOnGalaxyBg)"
        class="absolute h-[100vh] w-full bg-gradient-to-t from-white via-white via-10% to-transparent"
        :class="{ 'via-5%': turnOnGalaxyBg }"
      />
    </div>
  </div>
</template>
