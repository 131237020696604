<script setup lang="ts">
import { useRouter } from "vue-router";
import { useSessionStore } from "user/stores/session";
import { useSpinStore } from "user/stores/spin";
import { intervalToDuration } from "date-fns";
import { useOnboardingStore } from "user/stores/onboarding";

const sessionStore = useSessionStore();
const spinStore = useSpinStore();
const onboardingStore = useOnboardingStore();
const router = useRouter();

const spinVouchers = sessionStore.currentUser?.currencies?.spin_vouchers || 0;

// ===============
const animateButton = ref(false);
const spinning = ref(false);
const isInProgress = ref(false);
// ===============

async function clickButton() {
  if (!spinVouchers && !validSpin.value) {
    return;
  }

  if (isInProgress.value === true) {
    return;
  }

  if (!router.currentRoute.value.fullPath.includes("true")) {
    try {
      isInProgress.value = true;
      await spinStore.spinWheel();
      animateButton.value = true;
      spinning.value = true;
    } catch (e) {
      console.error(e);
    } finally {
      isInProgress.value = false;
    }
  }
}

const currentStars = ref(0);
// 24 hr in ms
const now = ref(new Date());

const freeSpinAt = new Date(sessionStore.currentUser?.free_spin_at!);

const intDuration = computed(() =>
  intervalToDuration({
    end: freeSpinAt,
    start: now.value,
  }),
);

function formatNumberToTimeView(n: number | string) {
  if (typeof n === "string") {
    return n;
  }
  return n.toString().padStart(2, "0");
}

const formattedDuration = computed(() => {
  let h: string | number = intDuration.value.hours ?? 0;
  let m: string | number = intDuration.value.minutes ?? 0;
  let s: string | number = intDuration.value.seconds ?? 0;

  h = h <= 0 ? "00" : h;
  m = m <= 0 ? "00" : m;
  s = s <= 0 ? "00" : s;

  return `${formatNumberToTimeView(h)} : ${formatNumberToTimeView(m)} : ${formatNumberToTimeView(s)}`;
});

onMounted(() => {
  currentStars.value = sessionStore.currentUser?.currencies.stars || 0;
});

const validSpin = computed(() => {
  return freeSpinAt <= now.value;
  // return currentStars.value > 50 && freeSpinAt <= now.value;
});

const modalOpen = ref(false);

function wheelSpun() {
  modalOpen.value = true;
}

function handleMarket() {
  modalOpen.value = false;
  router.push({ name: "market-all", replace: true });
}

function handleBackpackUgc() {
  modalOpen.value = false;
  router.push({ name: "backpack-ugc", replace: true });
}

function handleHome() {
  modalOpen.value = false;
  router.push({ name: "home", replace: true });
}

watch(now, () => {
  setTimeout(() => {
    now.value = new Date();
  }, 1000);
});

onMounted(() => {
  setTimeout(() => {
    now.value = new Date();
  }, 1000);
  if (
    router.currentRoute.value.fullPath.includes("true") &&
    onboardingStore.pendingRewards != null
  ) {
    spinStore.setOnboardingSpin(onboardingStore.pendingRewards[0]);
  }
});

onUnmounted(() => {
  spinStore.$reset();
});
</script>

<template>
  <STWLayout>
    <div
      v-if="spinVouchers === 0 && !validSpin"
      class="bg-dazzlingBlue/50 font-bold text-[14rem]/[24rem] md:text-[20rem]/[30rem] lg:text-[24rem]/[34rem] text-white flex justify-center mt-4 md:mt-8"
    >
      Come back later for a FREE Spin!
    </div>

    <div class="flex justify-center w-full relative mt-4">
      <SpinBillboard
        :formatted-duration="formattedDuration"
        :free-spin-available="validSpin"
        :spin-vouchers="spinVouchers"
      />
    </div>

    <SpinTheWheelHome
      :class="{ 'cursor-wait': isInProgress }"
      :spin="spinning"
      :button-animate="animateButton"
      :wheel-display="spinStore.wheelspinDisplay"
      @click="clickButton"
      @done="wheelSpun"
      :free-spin-available="validSpin"
    />

    <div v-if="spinStore.wheelspinReward != null">
      <ReceiveModalLayout
        v-model:open="modalOpen"
        :prize="spinStore.wheelspinReward"
        @go-to-backpack="handleBackpackUgc"
        @go-to-home="handleHome"
        @go-to-market="handleMarket"
      />
    </div>
  </STWLayout>
</template>
