<script setup lang="ts">
import { Mission, MissionClassName, MissionFilter } from "user/models/mission";
import Gems from "user/assets/card-icons/GEMs-Icon.png";
import Lootbox from "user/assets/card-icons/Lootbox-Icon.png";
import Others from "user/assets/card-icons/Others-Icon.png";
import Stars from "user/assets/card-icons/STARs-Icon.png";
import Ugc from "user/assets/card-icons/UGCs-Icon.png";
import Robux from "user/assets/card-icons/robux.png";
import RobuxCard from "user/assets/card-icons/robux-card.png";
import STW from "user/assets/card-icons/stw-icon.png";
import { RewardClassName } from "user/models/items";
import { useMissionStore } from "user/stores/mission";
import { useBrandStore } from "user/stores/brand";
import { useHandleMission } from "user/composables/useHandleMission";
import { ExternalServices } from "user/models/user";
import { useSessionStore } from "user/stores/session";

const router = useRouter();
const missionStore = useMissionStore();
const sessionStore = useSessionStore();
const signupReminder = ref(false);

const props = withDefaults(
  defineProps<{
    mission: Mission;
    scale?: boolean;
    turnOnZoomOutAnimation?: boolean;
  }>(),
  { turnOnZoomOutAnimation: false },
);

const animationID = ref(0);
const claimBlock = ref<HTMLDivElement | null>(null);

const currentIndex = ref<number>(0);
const startTime = ref<number | null>(null);
const intervalDuration = 2000;
const isInProgress = ref(false);

const { startMission, completeMission } = useHandleMission();

function robloxAuthenticated() {
  {
    return sessionStore.currentUser?.authorization_providers.some(
      (e) => e === ExternalServices.Roblox,
    );
  }
}

async function handleMission() {
  if (props.mission.isCompleted) return;

  if (isInProgress.value === true) {
    return;
  }

  try {
    if (props.mission.isActive) {
      if (props.mission.class_name === MissionClassName.RobloxUpdate) {
        if (!robloxAuthenticated()) {
          signupReminder.value = true;
          return;
        }
      }

      isInProgress.value = true;
      await completeMission(props.mission);
    }

    if (props.mission.isAvailable) {
      isInProgress.value = true;
      await startMission(props.mission);

      if (!props.turnOnZoomOutAnimation) {
        await missionStore.fetchMissions();
        return;
      }

      requestAnimationFrame(animateSuccessStartMission);
    }
  } catch (error) {
    requestAnimationFrame(animateErrorHandleMission);
  } finally {
    isInProgress.value = false;
  }
}

const cardRef = ref<HTMLDivElement | null>(null);

function animateSuccessStartMission() {
  if (!cardRef.value) {
    return;
  }

  cardRef.value.classList.add("animate-zoomout");

  setTimeout(async () => {
    if (!cardRef.value) {
      return;
    }
    cardRef.value.classList.add("hidden");
    cardRef.value.classList.remove("animate-zoomout");
    await missionStore.fetchMissions();
  }, 1000);
}

const rewardIcons = computed(() => {
  if (!props.mission.rewards?.length) {
    return [Others];
  }

  const icons: string[] = [];
  const obj: Partial<Record<RewardClassName, null>> = {};

  props.mission.rewards.forEach((reward) => {
    if (obj[reward.class_name as RewardClassName] !== undefined) {
      return;
    }
    if (RewardClassName.Gems === reward.class_name) {
      icons.push(Gems);
    }
    if (RewardClassName.Lootbox === reward.class_name) {
      icons.push(Lootbox);
    }
    if (RewardClassName.Badge === reward.class_name) {
      icons.push(Others);
    }
    if (RewardClassName.Stars === reward.class_name) {
      icons.push(Stars);
    }
    if (RewardClassName.UGC === reward.class_name) {
      icons.push(Ugc);
    }
    if (RewardClassName.Robux === reward.class_name) {
      icons.push(Robux);
    }
    if (RewardClassName.RobuxCard === reward.class_name) {
      icons.push(RobuxCard);
    }
    if (RewardClassName.STW === reward.class_name) {
      icons.push(STW);
    }
    obj[reward.class_name as RewardClassName] = null;
  });

  return icons;
});

const isDone = computed(() =>
  missionStore
    .getMissions(MissionFilter.Completed)
    ?.some((mis) => mis.identifier == props.mission.identifier),
);

const brand = computed(() => {
  return useBrandStore().brands?.find((brand) =>
    brand.missions.find((mis) => mis.id === props.mission.id),
  );
});

function animate(timestamp: number) {
  if (!startTime.value) startTime.value = timestamp;
  const elapsed = timestamp - startTime.value;

  if (elapsed >= intervalDuration) {
    startTime.value = performance.now();
    currentIndex.value = (currentIndex.value + 1) % rewardIcons.value.length;
    requestAnimationFrame(animate);
  } else {
    requestAnimationFrame(animate);
  }
}

function animateErrorHandleMission() {
  if (!claimBlock.value) {
    return;
  }

  claimBlock.value.classList.add("animate-error-shake-percent-offset");

  setTimeout(() => {
    if (!claimBlock.value) {
      return;
    }
    claimBlock.value.classList.remove("animate-error-shake-percent-offset");
  }, 2000);
}

onMounted(() => {
  if (rewardIcons.value.length > 1) {
    animationID.value = requestAnimationFrame(animate);
  }
});

onUnmounted(() => {
  cancelAnimationFrame(animationID.value);
});
</script>

<template>
  <div
    class="text-[14rem]/[18rem] w-[95%] flex flex-col rounded-ml shadow-referral relative hover:scale-105 transition-all"
    :class="{
      'scale-item': scale,
    }"
    ref="cardRef"
  >
    <div
      class="flex items-center rounded-t-ml px-4 py-2 min-h-[34rem]"
      :class="{
        'bg-aquaToPurple': !mission.isCompleted,
        'bg-grayToBlack': mission.isCompleted,
      }"
    >
      <p class="max-w-[70%]">{{ brand ? brand.name : "General" }}</p>
    </div>
    <div
      class="flex items-center justify-between rounded-b-ml px-4 py-4 bg-white"
    >
      <div class="flex gap-2 basis-3/4 items-center">
        <div
          class="max-w-[60rem] min-w-[40rem] max-w-1/6 flex items-center justify-center rounded-ml"
        >
          <img
            :src="mission.icon"
            alt="Icon"
            class="object-contain aspect-square max-h-[40rem] rounded-ml"
          />
        </div>
        <div class="flex flex-col gap-1">
          <p class="font-bold">{{ mission.name }}</p>
          <p
            v-html="
              mission.short_description
                ? mission.short_description
                : mission.description
            "
            class="[&>a]:text-blueOblivion [&>a]:underline"
          ></p>
        </div>
      </div>
      <div class="w-[40rem] h-[40rem] relative hover:scale-110 transition-all">
        <div
          class="claim-block shadow-claim absolute aspect-square w-[80rem] h-[72rem] bottom-0 -translate-x-1/2 flex justify-center rounded-ml border-2 border-white"
          @click.stop.prevent="handleMission"
          :class="{
            'bg-[#0038FF]': mission.isActive || mission.isAvailable,
            'bg-[#8a8a8a]': mission.isCompleted,
            'active:scale-125': !mission.isCompleted,
            'cursor-wait': isInProgress,
          }"
          ref="claimBlock"
        >
          <img
            v-for="(img, index) of rewardIcons"
            :src="img"
            alt="Icon"
            class="absolute -top-[65%] transition-opacity duration-1000 ease-in-out min-w-[86rem]"
            :class="{
              'animate-reward':
                index === currentIndex && rewardIcons.length !== 1,
              'opacity-0': index !== currentIndex,
            }"
          />
          <span
            class="text-white font-bold text-[14rem] bottom-2 absolute"
            :class="{ 'opacity-50': isDone }"
          >
            {{ mission.isAvailable ? "START" : "CLAIM" }}
          </span>
        </div>
      </div>
    </div>
    <SignupReminderDialog
      v-model:open="signupReminder"
      @confirm="router.push({ name: 'profile' })"
    />
  </div>
</template>

<style>
.scale-item:active:not(:has(.claim-block:active)) {
  transform: scale(98%);
}
</style>
