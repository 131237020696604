<script setup lang="ts">
import { useRouter } from "vue-router";
import backArrowWhite from "user/assets/icon-back-arrow.svg";
import backArrowBlack from "user/assets/icons/chevron-left.svg";

defineProps<{
  back?: boolean;
  title?: boolean;
  blackArrow?: boolean;
}>();

const router = useRouter();
</script>

<template>
  <div class="z-30 flex h-[52rem] w-full items-center px-4 py-3">
    <img
      v-if="back"
      :src="blackArrow ? backArrowBlack : backArrowWhite"
      alt=""
      @click="router.back()"
    />

    <div class="flex-1" />

    <slot />
    <div v-if="title" class="flex-1" />
    <div v-if="title" class="h-6 w-6" />
  </div>
</template>
