<script setup lang="ts">
import ArrowLeftIcon from "user/assets/icons/chevron-left.svg";

const router = useRouter();
</script>

<template>
  <div
    class="flex justify-between pt-[55rem] basis-[110rem] relative items-end py-3 px-5 bg-white md:bg-transparent"
  >
    <div class="w-[30rem]">
      <img
        :src="ArrowLeftIcon"
        alt="Go back"
        class="rounded-full p-1"
        @click="router.back"
      />
    </div>
    <h2 class="text-[20rem]/[28rem] grow text-center font-semibold">
      <slot />
    </h2>
    <div class="w-[30rem]" />
  </div>
</template>
