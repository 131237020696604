<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ref } from "vue";
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import gradient from "user/assets/market/bg-modal-special.png";
import { Rarity } from "user/models/items";

const open = defineModel<boolean>("open", { required: true });

const emit = defineEmits<{
  next: [];
}>();

const prizeAnimationActive = ref(false);
const done = ref(true);

function next() {
  emit("next");
}
watch(open, () => {
  if (open.value) {
    prizeAnimationActive.value = true;
  }
});
</script>

<template>
  <TransitionRoot :show="open">
    <Dialog as="div" class="relative z-[70]" @close="open = false">
      <TransitionChild
        as="template"
        enter="duration-100 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-100 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <img class="fixed inset-0 z-30" :src="gradient" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          class="fixed py-10 left-0 right-0 top-0 z-50 m-auto flex h-full w-[90vw] flex-col items-center justify-between"
        >
          <div class="z-40 h-[50rem] text-center">
            <div
              class="text-[30rem] font-bold text-white [text-shadow:0px_4px_4px_rgba(0,0,0,.5)]"
              :class="{
                hidden: !done,
              }"
            >
              HURRAY!
            </div>
          </div>
          <div class="fixed -top-[20rem] h-full w-full">
            <LootboxAnimation
              :activate="true"
              :prize="{ rarity: Rarity.special }"
            ></LootboxAnimation>
          </div>
          <div class="flex flex-col gap-2">
            <FlatButton
              label="Next"
              @click="next"
              :size="ButtonSize.lg"
              class="z-[60]"
              :class="{
                hidden: !done,
              }"
            />
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
