<script setup lang="ts">
import NavBarItem from "user/components/NavBarItem.vue";
import SignupReminderDialog from "user/components/SignupReminderDialog.vue";
import scan from "user/assets/scan.svg";
import navHome from "user/assets/nav-home.svg";
import navMissions from "user/assets/nav-missions.svg";
import navBackpack from "user/assets/nav-backpack.svg";
import navProfile from "user/assets/nav-profile.svg";
import navMarket from "user/assets/nav-market.svg";
import navInactiveHome from "user/assets/nav-inactive-home.svg";
import navInactiveMissions from "user/assets/nav-inactive-missions.svg";
import navInactiveBackpack from "user/assets/nav-inactive-backpack.svg";
import navInactiveProfile from "user/assets/nav-inactive-profile.svg";
import navInactiveMarket from "user/assets/nav-inactive-market.svg";
import { useSessionStore } from "user/stores/session";

const router = useRouter();

const sessionStore = useSessionStore();

const isAnonymous = computed(() => sessionStore.isAnonymous);

const signUpReminderOpen = ref(false);

function openSignupModal() {
  signUpReminderOpen.value = true;
}
async function login() {
  router.push({ name: "login" });
}
</script>

<template>
  <div class="md:hidden">
    <nav
      class="fixed bottom-0 left-0 z-20 h-[70rem] grid-cols-5 w-full grid justify-items-center gap-x-4 bg-white px-4 py-2.5 drop-shadow-[0px_-4px_15px_rgba(108,118,175,0.25)]"
    >
      <NavBarItem
        to="/home"
        label="Home"
        :image="navHome"
        :inactive-image="navInactiveHome"
        :anon-user="isAnonymous"
      />
      <NavBarItem
        to="/market/all"
        label="Market"
        :image="navMarket"
        @block="openSignupModal"
        block
        :inactive-image="navInactiveMarket"
        :anon-user="isAnonymous"
      />
      <NavBarItem
        to="/missions/missions/available"
        label="Quests"
        :image="navMissions"
        :inactive-image="navInactiveMissions"
        :anon-user="isAnonymous"
      />

      <!-- <div class="relative flex flex-1 items-center justify-center self-center">
        <RouterLink
          to="/scan"
          exact-active-class="!opacity-100"
          class="pointer-events-auto"
        >
          <img :src="scan" alt="" class="h-[52rem]" />
        </RouterLink>
      </div> -->

      <NavBarItem
        to="/backpack/ugc"
        label="Backpack"
        :image="navBackpack"
        :inactive-image="navInactiveBackpack"
        :anon-user="isAnonymous"
      />

      <NavBarItem
        to="/profile"
        label="Profile"
        :image="navProfile"
        :inactive-image="navInactiveProfile"
        :anon-user="isAnonymous"
        @block="openSignupModal"
        block
      />
    </nav>
    <SignupReminderDialog v-model:open="signUpReminderOpen" @confirm="login" />
    <nav
      class="pointer-events-none fixed bottom-0 left-0 right-0 flex h-[77rem] w-full items-end rounded-t-[30rem] py-2.5"
    >
      <div class="relative flex flex-1 items-center justify-center self-center">
        <RouterLink
          to="/scan"
          exact-active-class="!opacity-100"
          class="pointer-events-auto"
        >
          <img :src="scan" alt="" class="h-[52rem]" />
        </RouterLink>
      </div>
    </nav>
  </div>
</template>
