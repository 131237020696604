import { injectable } from "inversify";
import { InitialRewards } from "user/models/initial-rewards";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";

@injectable()
export class OnboardingApi {
  axios = container.get(Api);

  async getUserWelcome(): Promise<InitialRewards> {
    const { data } = await this.axios.get<InitialRewards>("/onboarding");
    return data;
  }
}
