<script lang="ts" setup>
import Button from "user/components/FlatButton.vue";
import SlideUp from "user/components/SlideUp.vue";
import accessoriesBackpack from "user/assets/icons/accessories-backpack.svg";
import getBackgroundColor from "user/utils/getBackgroundColor";
import {
  isLootBox,
  isMerch,
  isSpins,
  isUGC,
  Reward,
  RewardClassName,
} from "user/models/items";
import { useVModel } from "@vueuse/core";

const props = defineProps<{
  open: boolean;
  loading?: boolean;
  item: Reward;
  gems?: boolean;
  username?: string;
  isErrorForMerch?: boolean;
}>();

const emit = defineEmits<{
  (event: "update:open", value: boolean): void;
  (event: "confirm"): void;
  (event: "update:username", value: string): void;
}>();

const username = useVModel(props, "username", emit);

function getClassNameLabel(className?: RewardClassName) {
  if (!className) {
    return "";
  }

  switch (className) {
    case RewardClassName.Merch:
      return "Merch";
    case RewardClassName.STW:
      return "Spins";
    case RewardClassName.Badge:
      return "Badge";
    case RewardClassName.Gems:
      return "GEMs";
    case RewardClassName.Lootbox:
      return "Lootbox";
    case RewardClassName.Stars:
      return "STARs";
    case RewardClassName.UGC:
      return "UGC";
    default:
      return "";
  }
}

function onUpdateOpen(value: boolean) {
  // prevent closing the slide up while "loading"

  if (!props.loading) {
    emit("update:open", value);
  }
}

const btnText = computed(() => {
  if (isUGC(props.item)) {
    return "Open In Roblox";
  }
  if (isLootBox(props.item)) {
    return "Open Lootbox";
  }
  if (isSpins(props.item)) {
    return "Spin the Wheel";
  }
  if (isMerch(props.item)) {
    return "Redeem";
  }

  return "";
});

function confirm() {
  emit("confirm");
}
</script>

<template>
  <SlideUp :open="open" @update:open="onUpdateOpen">
    <div class="flex w-[320rem] flex-col gap-y-6">
      <!-- Content -->
      <div
        class="text-nl-0 flex flex-col gap-y-[20rem] text-center"
        :class="{ 'opacity-50': loading }"
      >
        <!-- <slot name="top" /> -->
        <div class="text-[20rem] font-semibold">{{ item?.name }}</div>
        <div v-if="item?.description" class="text-sm">
          {{ item.description }}
        </div>

        <div v-if="item" class="self-center">
          <div
            class="h-[320rem] w-[320rem] relative rounded-ml flex justify-center items-center overflow-clip shadow-home"
            :class="[
              getBackgroundColor(item.rarity, 'radial', item.class_name),
            ]"
          >
            <img :src="item.icon" alt="" class="object-contain h-full w-full" />
          </div>
        </div>
      </div>

      <div v-if="!isMerch(item)" class="flex h-[53rem] w-full justify-center">
        <div class="flex gap-3 justify-center w-full">
          <img :src="accessoriesBackpack" alt="" />
          <div class="text-lg font-bold">
            <div>Rarity</div>
            <div>
              {{ getClassNameLabel(item?.class_name) }}
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="isMerch(item)"
        class="flex flex-col w-full justify-center items-center gap-4"
      >
        <FunInput
          id="discord-name"
          class="text-[18rem]/[25rem] shadow-home rounded-ml border-2 placeholder:text-center transition-all"
          placeholder="Enter Your Discord Username"
          v-model="username"
          :class="{
            'border-redHanded': isErrorForMerch,
          }"
        />
        <div class="text-[15rem]/[22rem] w-[70%] text-center flex">
          We will contact you with redemption details!
        </div>
      </div>

      <div class="flex flex-col items-center gap-y-3">
        <Button
          :label="btnText"
          loading-label="Loading"
          :loading="loading"
          @click="confirm"
        />
      </div>
    </div>
  </SlideUp>
</template>
