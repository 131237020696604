<script setup lang="ts">
import { Mission } from "user/models/mission";
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import BackButton from "user/components/BackButton.vue";
import play from "user/assets/play.svg";
import { useNotificationStore } from "user/stores/notifications";
import { useMissionStore } from "user/stores/mission";
import { useBrandStore } from "user/stores/brand";
import { storeToRefs } from "pinia";

const router = useRouter();
const route = useRoute();

const missionStore = useMissionStore();
const brandStore = useBrandStore();

const { missionsMap } = storeToRefs(missionStore);

const missions = computed(() => {
  const m = brandStore.selectedBrand?.missions.map((el) => {
    const m = missionsMap.value[el.id];
    if (!m) {
      return el;
    }
    return m;
  });
  return m;
});

async function click(url: string) {
  window.open(url, "_blank");
  handleClose();
  setTimeout(
    async () => await useNotificationStore().fetchNotifications(),
    200,
  );
}

const allRewards = computed(() => {
  let rewards = [];
  const unique: Record<string, null> = {};

  if (!brandStore.selectedBrand?.missions.length) {
    return [];
  }

  if (brandStore.selectedBrand?.missions?.length > 0) {
    for (const mis of brandStore.selectedBrand?.missions) {
      for (const r of mis.rewards) {
        if (unique[r.id] !== undefined) {
          continue;
        }

        rewards.push({
          mission: mis,
          reward: r,
        });

        unique[r.id] = null;
      }
    }
  }
  return rewards;
});

function openMission(mission: Mission) {
  router.push({
    query: {
      missionId: mission.id,
    },
  });
}

// const daysLeft = computed(() => {
//   if (!missionStore.selectedMission) {
//     return 5;
//   }

//   try {
//     const start = Date.parse(String(missionStore.selectedMission.expires_at));
//     const duration = intervalToDuration({
//       end: new Date(),
//       start,
//     });

//     if (!duration.days) {
//       return 5;
//     }

//     return Math.abs(duration.days) ?? 4;
//   } catch (err) {
//     return 5;
//   }
// });

function handleClose() {
  router.push({
    name: "missions",
    params: { type: "brands", status: "in-progress" },
  });
}

onBeforeMount(() => {
  if (!brandStore.selectedBrand) {
    let currentBrand = brandStore.getBrandsById(Number(route.params.id));
    if (currentBrand) {
      brandStore.setSelectBrand(currentBrand);
      return;
    }
    currentBrand = brandStore.getBrandsByIdentifier(route.params.id as string);
    if (currentBrand) {
      brandStore.setSelectBrand(currentBrand);
    }
  }
});

onUnmounted(() => {
  brandStore.resetSelectBrand();
});
</script>

<template>
  <Layout>
    <div class="flex h-full w-full flex-col bg-white">
      <!-- header (image/backbutton) -->
      <div class="relative w-full bg-milestone backdrop-blur-xl">
        <BackButton
          class="absolute left-[17rem] top-[30rem] z-30"
          @click="handleClose"
        />
        <img
          :src="brandStore.selectedBrand?.icon"
          alt=""
          class="object-cover h-full w-full m-auto"
        />
      </div>
      <div class="flex flex-col px-[10rem] pt-[20rem] bg-white">
        <div class="flex justify-between w-full mb-3">
          <div class="text-[20rem]/[26rem] font-semibold">
            {{ brandStore.selectedBrand?.name }}
          </div>
          <!-- <img :src="badge" class="self-start" /> -->
          <div class="w-5"></div>
          <!-- <MissionBanner
            v-if="brandStore.selectedBrand"
            :text="`Ends in ${daysLeft} days`"
          /> -->
        </div>
        <div
          class="mb-[21rem] text-[15rem]/[22rem] text-roboMaster [&>a]:text-blueOblivion [&>a]:underline"
          v-html="brandStore.selectedBrand?.description"
        ></div>
        <!--  buttons -->
        <div class="self-center w-full">
          <FlatButton
            v-if="brandStore.selectedBrand?.game"
            label=""
            :icon="play"
            :size="ButtonSize.lg"
            play
            borderless
            class="w-full"
            @click="click(brandStore.selectedBrand?.game)"
          />
          <FlatButton
            v-else-if="brandStore.selectedBrand?.website"
            label="Visit Website"
            :size="ButtonSize.lg"
            borderless
            @click="click(brandStore.selectedBrand?.website)"
            class="mb-2 w-full"
          />
        </div>
        <div class="mt-5 gap-y-4 flex flex-col items-center">
          <div class="mb-3 font-medium text-base">Quests:</div>
          <MissionCard
            v-for="item of missions"
            :mission="item"
            :key="item.id"
            @click="openMission(item)"
            scale
          />
        </div>
        <div class="mb-3 mt-[60rem] text-base font-semibold">Rewards:</div>
        <div
          class="flex gap-x-3 font-medium mb-[60rem] overflow-auto no-scrollbar"
        >
          <RewardCard
            v-for="(item, index) in allRewards"
            :key="index"
            :reward="item.reward"
            :brand-name="brandStore.selectedBrand?.name"
            @click="openMission(item.mission)"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
