<template>
  <PageSection class="px-5">
    <template #label> Referrals </template>
    <div
      class="flex gap-2 -ml-[10rem] flex-col md:flex-row md:overflow-auto md:no-scrollbar"
    >
      <ReferralCard />
    </div>
  </PageSection>
</template>
