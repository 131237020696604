<script setup lang="ts">
import Modal from "user/components/Modal.vue";
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import ChevronRight from "user/assets/icons/gem-arrow-right.svg";
import Star from "user/assets/icons/star-icon.png";
import Gem from "user/assets/icons/gem-icon.png";

defineEmits<{
  market: [];
  keepBrowsing: [];
}>();

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <Modal v-model:open="open">
    <div
      class="flex flex-col items-center px-[47rem] py-[50rem] h-full rounded-ml text-center justify-between gap-y-3"
    >
      <div class="pb-4 text-[30rem] font-semibold">OH WAIT!</div>
      <div class="line- text-[20rem] font-medium">
        You don't have enough GEMs. Buy GEMs with STARs!
      </div>
      <div class="flex justify-center items-center">
        <img :src="Star" alt="" class="object-contain w-1/3 h-full" />
        <img :src="ChevronRight" alt="" class="object-contain w-1/3 h-1/2" />
        <img :src="Gem" alt="" class="object-contain w-1/3 h-full" />
      </div>
      <FlatButton
        label="Keep Browsing"
        :size="ButtonSize.md"
        hollow
        @click="$emit('keepBrowsing')"
      />
      <FlatButton
        label="Go to GEMs Shop"
        :size="ButtonSize.md"
        @click="$emit('market')"
      />
    </div>
  </Modal>
</template>
