<script setup lang="ts">
import { type DotLottiePlayer } from "@aarsteinmedia/dotlottie-player";
import { SpinReward } from "user/models/spin";
import threeStarsAnimation from "user/assets/lotties/spin-the-wheel/STW-3 STARs.json";
import sixStarsAnimation from "user/assets/lotties/spin-the-wheel/STW-6-STARs.json";
import bearAnimation from "user/assets/lotties/spin-the-wheel/STW-Bear Backpack.json";
import greenLootboxAnimation from "user/assets/lotties/spin-the-wheel/STW-Green Lootbox.json";
import animation5 from "user/assets/lotties/spin-the-wheel/STW-Mystery Box.json";
import animation6 from "user/assets/lotties/spin-the-wheel/STW-Robux.json";
import animation7 from "user/assets/lotties/spin-the-wheel/STW-Yellow Lootbox.json";
import animation8 from "user/assets/lotties/spin-the-wheel/STW-TL Bear.json";
// import staticImage from "user/assets/spin-wheel-prize.png";

const props = defineProps<{
  animate?: boolean;
  wheelDisplay: SpinReward;
  freeSpinAvailable: boolean;
}>();

const animation = computed(() => {
  switch (props.wheelDisplay) {
    case SpinReward.StarsHigh:
      return sixStarsAnimation;
    case SpinReward.StarsLow:
      return threeStarsAnimation;
    case SpinReward.Ugc:
      return bearAnimation;
    case SpinReward.Legendary:
      return animation8;
    case SpinReward.UgcLootbox:
      return greenLootboxAnimation;
    case SpinReward.StarsLootbox:
      return animation7;
    case SpinReward.Robux:
      return animation6;
    default:
    case SpinReward.Mystery:
      return animation5;
  }
});

const playerRef = ref<DotLottiePlayer>();

const emit = defineEmits<{
  done: [];
}>();

watch(
  () => props.animate,
  () => {
    if (props.animate) {
      playerRef.value?.load(animation.value).then(() => {
        playerRef.value?.play();
      });
    }
  },
);

onMounted(() => {
  playerRef.value?.addEventListener("complete", () => {
    emit("done");
  });

  playerRef.value?.addEventListener("play", () => {
    console.log("play");
  });
});
</script>

<template>
  <dotlottie-player
    ref="playerRef"
    :src="animation"
    class="fixed bottom-[70rem] w-full md:pr-[260rem] lg:pr-[414rem] md:bottom-0"
    :class="{
      'h-[85%] md:h-[95%]': !freeSpinAvailable,
      'h-[90%] md:h-[100%]': freeSpinAvailable,
    }"
  />
</template>
