import { injectable } from "inversify";
import { type User } from "user/models/user";
import axios from "axios";

@injectable()
export class SessionApi {
  async getCurrentSession(): Promise<User> {
    const { data } = await axios.get<User>("/profile");
    return data;
  }

  async destroyCurrentSession(): Promise<void> {
    try {
      await axios.delete("/user/session/current");
    } catch (e) {
      console.log(e);
    }
  }
}

export class InvalidEmailCodeError extends Error {
  constructor() {
    super();
    this.name = "InvalidEmailCode";
  }
}
