export enum SpinReward {
  StarsHigh = "stars_high",
  StarsLow = "stars_low",
  Ugc = "ugc",
  Legendary = "legendary",
  UgcLootbox = "ugc_lootbox",
  StarsLootbox = "stars_lootbox",
  Robux = "robux",
  Mystery = "mystery_box",
}
