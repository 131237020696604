<script setup lang="ts">
import { SpinReward } from "user/models/spin";
import WheelAnimation from "user/components/home/stw/WheelAnimation.vue";
import STWButton from "user/components/home/stw/STWButton.vue";
const emit = defineEmits<{
  done: [];
  click: [];
}>();

const props = defineProps<{
  spin: boolean;
  buttonAnimate: boolean;
  wheelDisplay: SpinReward;
  freeSpinAvailable: boolean;
}>();

function clickButton() {
  if (!props.spin) {
    emit("click");
  }
}

function done() {
  emit("done");
}
</script>

<template>
  <div class="relative grow">
    <WheelAnimation
      :animate="buttonAnimate"
      @done="done"
      :wheel-display="wheelDisplay"
      :free-spin-available="freeSpinAvailable"
    />
    <STWButton
      :animate="buttonAnimate"
      @click="clickButton"
      :free-spin-available="freeSpinAvailable"
    />
  </div>
</template>
