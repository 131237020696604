<script setup lang="ts">
import { OnboardingReward } from "user/models/initial-rewards";
import { isStarsLootbox, Reward } from "user/models/items";
import { ButtonSize } from "user/components/FlatButton.vue";

defineProps<{
  prize: Reward | OnboardingReward;
  hideTextMB: boolean;
  mbAnimationActive: boolean;
  isAnimated: boolean;
}>();

const emit = defineEmits<{
  openMysteryBox: [];
  endMysteryBoxAnimation: [];
}>();
</script>

<template>
  <div class="flex flex-col justify-center items-center min-h-[190rem] gap-4">
    <ReceiveModalHeader
      :class="{
        'text-black': isStarsLootbox(prize),
        hidden: hideTextMB,
      }"
    >
      HOORAY!
    </ReceiveModalHeader>
    <div
      v-if="!hideTextMB"
      class="text-center text-[20rem]/[28rem] font-medium"
    >
      <span> You’ve won a Mystery Box from playing Spin The Wheel! </span>
    </div>
  </div>
  <div class="fixed flex justify-center items-center top-0 h-full w-full">
    <LootboxAnimation
      :activate="mbAnimationActive"
      @done="emit('endMysteryBoxAnimation')"
      :prize="prize"
    ></LootboxAnimation>
  </div>

  <div
    class="flex flex-col gap-2 min-h-[190rem] justify-end"
    :class="{
      hidden: hideTextMB,
    }"
  >
    <FlatButton
      label="Next"
      @click="emit('openMysteryBox')"
      :size="ButtonSize.md"
      class="z-[60] shadow-home"
      :disabled="isAnimated"
    />
  </div>
</template>
