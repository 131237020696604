import container from "shared/inversify.config";

import { defineStore } from "pinia";
import { ref, shallowReadonly } from "vue";
import { Notification } from "user/models/notifications";
import { NotificationApi } from "user/api/notifications";
import { useSessionStore } from "./session";

export const useNotificationStore = defineStore("notification", () => {
  const notificationQueue = ref<Notification[]>([]);
  const indexInQueue = ref<number>(0);
  const session = useSessionStore();

  function $reset() {
    notificationQueue.value = [];
    indexInQueue.value = 0;
  }

  async function fetchNotifications() {
    if (notificationQueue.value.length == 0 && indexInQueue.value == 0) {
      const notificiations = await container
        .get(NotificationApi)
        .getNotifications();

      // FILTER OUT NEW
      notificationQueue.value = (notificiations as Notification[]).filter(
        (notif) => notif.reward != null && notif.new,
      );

      if (notificationQueue.value.length > 0) {
        session.tryFetchCurrentUser();
      }
    }
  }
  /// returns wether or not you should continue to the next category
  function stepNotificationAndProgress() {
    indexInQueue.value = indexInQueue.value + 1;
    if (indexInQueue.value == notificationQueue.value.length) {
      $reset();
      return true;
    }
    return false;
  }

  function getRewardById(index: number) {
    if (index <= notificationQueue.value.length) {
      return notificationQueue.value[index];
    }
  }

  return {
    $reset,
    notificationQueue: shallowRef(notificationQueue),
    indexInQueue: shallowReadonly(indexInQueue),
    fetchNotifications,
    stepNotificationAndProgress,
    getRewardById,
  };
});
