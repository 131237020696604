<script setup lang="ts">
import {
  Dialog,
  DialogTitle,
  DialogPanel,
  TransitionRoot,
  TransitionChild,
} from "@headlessui/vue";
import FunButton from "user/components/FunButton.vue";
import LegalContent from "user/components/LegalContent.vue";

defineProps<{
  title: string;
  html: string;
}>();

defineOptions({
  inheritAttrs: false,
});

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <TransitionRoot :show="open">
    <Dialog as="div" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 z-50 bg-black/50" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-[opacity,transform]"
        enter-from="opacity-0 translate-y-[100rem]"
        enter-to="opacity-100 translate-y-0"
        leave="duration-200 ease-in transition-[opacity,transform]"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          class="fixed inset-0 z-50 m-auto flex h-[70vh] max-h-[630px] w-[90vw] max-w-[980px] flex-col rounded-[20rem] border-2 border-b-[6rem] border-black px-4 py-6 md:px-10"
          v-bind="$attrs"
        >
          <DialogTitle
            class="text-center text-[28rem] font-semibold text-coated text-white"
          >
            {{ title }}
          </DialogTitle>

          <LegalContent
            class="mb-8 mt-4 flex-1 rounded-[8rem] bg-white/75 p-4 text-bovine md:px-10 md:py-6"
            :html="html"
          />

          <FunButton
            class="!absolute -bottom-[30rem] left-0 right-0 mx-auto w-[140rem] md:-bottom-9 md:w-[216rem]"
            label="Close"
            @click="open = false"
          />
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
