<script setup lang="ts">
import { ref } from "vue";

const logoutOpen = ref(false);
</script>

<template>
  <Layout class="pb-[86rem] md:pb-4">
    <div class="flex flex-col w-full h-full">
      <ActionBar back black-arrow />
      <div class="mt-6 px-5 font-semibold z-30 grow">
        <div class="mb-6 text-3xl font-semibold">Settings</div>
        <div class="flex flex-col last:border-b-0">
          <RouterLink
            :to="{ name: 'profile-referrals' }"
            class="border-b border-b-white py-4 text-base"
            >Referrals</RouterLink
          >
          <RouterLink
            to="/settings/feedback"
            class="border-b border-b-white py-4 text-base"
            >Feedback</RouterLink
          >
          <RouterLink
            to="/settings/terms"
            class="border-b border-b-white py-4 text-base"
          >
            Terms & Conditions
          </RouterLink>
          <RouterLink
            to="/settings/privacy"
            class="border-b border-b-white py-4 text-base"
          >
            Privacy Policy
          </RouterLink>
          <button
            class="border-b border-b-white py-4 text-left text-base"
            @click="logoutOpen = true"
          >
            Sign Out
          </button>
        </div>
      </div>
      <div class="flex-1" />
      <div class="text-center text-[11rem]">
        <div>App Version: 1.0.0.00</div>
        <div>© 2023 The Littles Studio ltd. All rights reserved.</div>
      </div>
    </div>
    <LogoutDialog v-model:open="logoutOpen" />
  </Layout>
</template>
