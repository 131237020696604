<script setup lang="ts">
import { OnboardingReward } from "user/models/initial-rewards";
import { isStarsLootbox, Reward } from "user/models/items";
import { ButtonSize } from "user/components/FlatButton.vue";
import capitalize from "user/utils/capitalize";
import { Currency } from "user/models/currency";

const props = defineProps<{
  prize: Reward | OnboardingReward;
  hideText: boolean;
  lbAnimationActive: boolean;
  isAnimated: boolean;
  isMarket?: boolean;
  sellingPrice?: Currency;
}>();

const emit = defineEmits<{
  openLootbox: [];
  startPrizeAnimation: [];
}>();

const isSoldForGems = computed(() => {
  return props.sellingPrice?.gems ? true : false;
});
</script>

<template>
  <div class="flex flex-col justify-center items-center gap-4 min-h-[190rem]">
    <ReceiveModalHeader
      :class="{
        'text-black': isStarsLootbox(prize),
        hidden: hideText,
      }"
    >
      HOORAY!
    </ReceiveModalHeader>
    <div v-if="!hideText" class="text-center text-[20rem]/[28rem] font-medium">
      <template v-if="isMarket">
        <span v-if="!isStarsLootbox(prize)">
          You've redeemed {{ prize.marketplace_quantity }}
          <span class="text-roboMaster">{{ capitalize(prize.rarity) }}</span>
          {{ " " }}
          <span class="font-bold"> {{ prize.name }} </span> with
          {{ isSoldForGems ? sellingPrice?.gems : sellingPrice?.stars }}
          {{ isSoldForGems ? "GEMs" : "STARs" }}!
        </span>

        <span v-else>
          You've redeemed {{ prize.marketplace_quantity }}
          <span class="font-bold">STARs Lootbox</span> with with
          {{ isSoldForGems ? sellingPrice?.gems : sellingPrice?.stars }}
          {{ isSoldForGems ? "GEMs" : "STARs" }}!
        </span>
      </template>

      <template v-else>
        <span v-if="!isStarsLootbox(prize)">
          You’ve won a
          <span class="text-roboMaster">{{ capitalize(prize.rarity) }}</span>
          {{ " " }}
          <span class="font-bold">
            {{ prize.name }}
          </span>
          from playing at the game booth!
        </span>

        <span v-else>
          You’ve won a <span class="font-bold">STARs Lootbox</span> from playing
          at the game booth!
        </span>
      </template>
    </div>
  </div>

  <div class="fixed flex justify-center items-center top-0 h-full w-full">
    <LootboxAnimation
      :activate="lbAnimationActive"
      @done="emit('startPrizeAnimation')"
      :prize="prize"
    ></LootboxAnimation>
  </div>

  <div
    class="flex flex-col gap-2 min-h-[190rem] justify-end"
    :class="{
      hidden: hideText,
    }"
  >
    <FlatButton
      label="Next"
      @click="emit('openLootbox')"
      :size="ButtonSize.md"
      class="z-[60] shadow-home"
      :disabled="isAnimated"
    />
  </div>
</template>
