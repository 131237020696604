<script lang="ts">
/* eslint-disable import/first,eslint-comments/disable-enable-pair,import/order,import/exports-last */
import type { InjectionKey } from "vue";

export const slideUpKey = Symbol() as InjectionKey<{
  close: (callback?: () => void) => void;
}>;
</script>

<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
const props = defineProps<{
  open: boolean;
  backdrop?: boolean;
}>();
const emit = defineEmits<{
  (event: "update:open", value: boolean): void;
}>();

function close(callback?: () => void) {
  // trigger the close of the slide up
  emit("update:open", false);

  if (callback != null) {
    // invoke the callback after the animation has finished
    // the extra 7ms here is due to the curve even though the duration is 200ms
    setTimeout(() => {
      callback();
    }, 207);
  }
}

defineExpose({ close });
provide(slideUpKey, { close });

// // close a slide up instead of going back on a back button press
// onNavigatorPop(() => {
//   if (props.open) {
//     emit("update:open", false);
//     return false;
//   }
// });
</script>
<template>
  <TransitionRoot :show="open" appear as="template">
    <Dialog @close="$emit('update:open', false)">
      <TransitionChild
        as="template"
        enter="transition-opacity duration-300 ease-[cubic-bezier(0,0.59,0,1.01)]"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-200 ease-[cubic-bezier(1,0.01,0.88,0.61)]"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="pointer-events-auto fixed inset-0 z-30 h-full w-full bg-mangosteen opacity-90 outline-none"
          tabindex="1"
          @touchend.stop
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="transition-transform duration-300 ease-[cubic-bezier(0,0.59,0,1.01)]"
        enter-from="translate-y-[100%]"
        enter-to="translate-y-0"
        leave="transition-transform duration-200 ease-[cubic-bezier(1,0.01,0.88,0.61)]"
        leave-from="translate-y-0"
        leave-to="translate-y-[100%]"
      >
        <DialogPanel
          class="pointer-events-auto fixed bottom-0 left-0 right-0 z-[100] flex max-h-[92%] w-full md:m-auto md:w-[425rem] flex-col rounded-t-[20rem]  bg-white"
        >
          <!-- Top -->
          <div class="flex h-[37rem] shrink-0 justify-center">
            <!-- Swipe Indicator -->
            <div class="mt-3 h-[5rem] w-14 rounded-lg bg-harborFog" />
          </div>

          <!-- Container -->
          <div
            class="flex max-h-full min-h-0 flex-1 flex-col items-center px-5 pb-10"
          >
            <slot :close="() => close()" />
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
