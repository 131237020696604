import { injectable } from "inversify";
import type { MissionFilter, MissionCategory } from "user/models/mission";
import type { Mission } from "user/models/mission";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";
import { AxiosResponse } from "axios";

@injectable()
export class MissionApi {
  axios = container.get(Api);
  // TODO: actually fetch mission
  async getMissionById(id: number): Promise<Mission> {
    const { data } = await this.axios.get<{ data: Mission }>(`/missions/${id}`); // ?? just implying this exists, to from json as well
    return data;
  }

  async getMissions(): Promise<MissionResponseStruct> {
    const { data } = await this.axios.get<{ data: MissionResponseStruct }>(
      "/missions",
    );

    return data;
  }

  async triggerLinkout(url: string): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/linkout?url=${url}`);

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    return { success: true, message: res.data };
  }

  async claimStandardMission(mission_id: number): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_standard_mission`, {
      mission_id
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    return { success: true, message: res.data };
  }

  async startMission(id: number): Promise<IMissionResponse> {
    return await this.axios.post(`/start_mission`, { mission_id: id });
  }

  async completeRobloxMission(id: number): Promise<IMissionResponse> {
    return await this.axios.post(`/claim_roblox_mission`, { mission_id: id });
  }

  async claimUgc(mission_id: number, code: string): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_mission_code`, {
      mission_id,
      code,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    return { success: true, message: res.data };
  }

  async claimReferralMission(mission_id: number): Promise<IMissionResponse> {
    const res = await this.axios.post<AxiosResponse>(`/claim_referral_event_mission`, {
      mission_id
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    return { success: true, message: res.data };
  }
}

interface IMissionResponse {
  success: boolean;
  message: string;
}

interface MissionResponseStruct {
  active: Mission[];
  available: Mission[];
  completed: Mission[];
}
