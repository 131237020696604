<script setup lang="ts">
import { useSettingStore } from "user/stores/settings";
import { ICustomCta } from "user/models/settings";
import { useBrandStore } from "user/stores/brand";
import { storeToRefs } from "pinia";

const props = defineProps<{
  name: string;
}>();

const brandStore = useBrandStore();
const router = useRouter();
const settingsStore = useSettingStore();

const { settings } = storeToRefs(settingsStore);

const sectionItems = computed(() => {
  if (!settings.value.custom_cta?.length) {
    return [];
  }

  return settings.value.custom_cta.filter(
    (i) => i.custom_cta_header === props.name,
  );
});

function getBanner(item: ICustomCta) {
  if (item.custom_cta_brand_id) {
    const b = brandStore.getBrandsById(item.custom_cta_brand_id);
    if (!b) {
      return;
    }
    return b.brand_banner ? (b.brand_banner as string) : b.icon;
  } else {
    return item.custom_cta_image;
  }
}

function openCard(item: ICustomCta) {
  if (item.custom_cta_brand_id) {
    router.push({
      name: "brand",
      params: {
        id: item.custom_cta_brand_id,
      },
    });
    return;
  }
  if (item.custom_cta_card_link) {
    window.open(item.custom_cta_card_link, "_blank");
  }
}
</script>

<template>
  <PageSection v-if="sectionItems.length">
    <template #label>
      {{ sectionItems[0].custom_cta_header }}
    </template>
    <!-- horizontal list -->
    <div class="no-scrollbar left-0 scroll-px-5 overflow-auto">
      <div class="flex gap-x-2">
        <!-- <div class="ml-[15rem]" /> -->
        <div
          v-if="sectionItems?.length > 0"
          v-for="item in sectionItems"
          :key="item.id"
          class="active:scale-90 min-w-fit"
        >
          <div @click="openCard(item)">
            <Banner :icon="getBanner(item)" scale />
          </div>
        </div>
        <!-- <div class="min-w-[15rem]" /> -->
      </div>
    </div>
  </PageSection>
</template>
