<script setup lang="ts">
import container from "shared/inversify.config";

import ActionBar from "user/components/ActionBar.vue";
import scanBgTop from "user/assets/scan-bg-top@2x.png";
import backpackBgBottom from "user/assets/backpack-bg-bottom@2x.png";
import { ref } from "vue";

import { useRouter } from "vue-router";

import { useQrScanner } from "user/composables/qr-scan";
import Layout from "./layout/Layout.vue";

const router = useRouter();
const videoElement = ref<HTMLVideoElement>();
const qrCamera = ref(true);

const invalidQrDialogOpen = ref(false);
const signUpReminderOpen = ref(false);
const scannedClaimIds = new Set<string>();

useQrScanner(videoElement, decodeQr);

function resumeCamera() {
  qrCamera.value = true;
}

async function decodeQr(content: string) {
  qrCamera.value = false;
}

async function onVideoClick() {
  const contents = await navigator.clipboard.readText();
  void decodeQr(contents);
}
</script>

<template>
  <Layout>
    <template #background>
      <img
        alt=""
        :src="scanBgTop"
        class="absolute left-0 right-0 top-0 w-full object-cover"
      />

      <img
        alt=""
        :src="backpackBgBottom"
        class="absolute bottom-0 left-0 right-0 w-full object-cover"
      />
    </template>

    <ActionBar />

    <div
      class="mt-[50rem] text-center text-[28rem] font-semibold text-white"
      @click="qrCamera = false"
    >
      SCAN QR CODE
    </div>

    <div
      class="mx-[15rem] mt-[60rem] flex h-[418rem] flex-col border-2 border-black bg-white px-[30rem] pt-[30rem]"
    >
      <div class="relative aspect-square w-full border-2 border-black bg-black">
        <video
          ref="videoElement"
          class="absolute h-full w-full object-cover object-center"
          @click="onVideoClick"
        ></video>
      </div>

      <div
        class="flex flex-1 items-center justify-center text-center text-[14rem] font-semibold"
      >
        Line up the QR code in the frame above!
      </div>
    </div>
  </Layout>
</template>
