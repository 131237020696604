<script setup lang="ts">
import { computed, ref } from "vue";
import iconGoogle from "user/assets/google.svg";
import iconDiscord from "user/assets/discord.svg";
import iconRoblox from "user/assets/roblox.svg";
import iconTwitter from "user/assets/x-logo.svg";
import OauthButton from "user/components/OauthButton.vue";

const props = defineProps<{
  disabled?: boolean;
}>();

defineOptions({
  inheritAttrs: false,
});

const googleForm = ref<HTMLFormElement>();

const googleFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=google",
  );
  return `/auth/google_oauth2`;
});

const discordForm = ref<HTMLFormElement>();

const discordFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=google",
  );
  return `/auth/discord`;
});

const appleForm = ref<HTMLFormElement>();

const appleFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=apple",
  );
  return `/auth/apple`;
});

const robloxForm = ref<HTMLFormElement>();

const robloxFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=roblox",
  );
  return `/auth/roblox`;
});

const twitterForm = ref<HTMLFormElement>();

const twitterFormAction = computed(() => {
  const uri = window.encodeURIComponent(
    window.location.origin + "?login=twitter2",
  );
  return `/auth/twitter2`;
});

async function googleLogin() {
  // await sessionStore.initiateOauthGoogle();
  if (props.disabled) return;
  googleForm.value!.submit();
}

async function discordLogin() {
  // await sessionStore.initiateOauthGoogle();
  if (props.disabled) return;
  discordForm.value!.submit();
}

function appleLogin() {
  if (props.disabled) return;
  appleForm.value!.submit();
}

function robloxLogin() {
  if (props.disabled) return;
  robloxForm.value!.submit();
}

function twitterLogin() {
  if (props.disabled) return;
  twitterForm.value!.submit();
}

// onMounted(async () => {
//   await sessionStore.fetchCSRF();
// });
</script>

<template>
  <div class="self-center">
    <div class="flex items-center gap-x-4" v-bind="$attrs">
      <!-- <OauthButton :icon="iconApple" @click="appleLogin" /> -->
      <!-- <button
        class="shadow-home w-full gap-2 flex items-center justify-center rounded-md py-4 bg-white text-[20rem] px-4 font-bold"
        @click="googleLogin()"
      >
        Sign in via Google
        <img :src="iconGoogle" class="h-[23rem] object-contain" />
      </button> -->
      <OauthButton :icon="iconGoogle" @click="googleLogin()" />
      <OauthButton :icon="iconDiscord" @click="discordLogin()" />
      <OauthButton :icon="iconRoblox" @click="robloxLogin()" />
      <OauthButton :icon="iconTwitter" @click="twitterLogin()" />
    </div>

    <ClientOnly>
      <form ref="googleForm" method="GET" :action="googleFormAction"></form>
      <form ref="discordForm" method="GET" :action="discordFormAction"></form>
      <!-- <form ref="appleForm" method="GET" :action="appleFormAction"></form> -->
      <form ref="robloxForm" method="GET" :action="robloxFormAction"></form>
      <form ref="twitterForm" method="GET" :action="twitterFormAction"></form>
    </ClientOnly>
  </div>
</template>
