import { injectable } from "inversify";
import { type Reward } from "user/models/items";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";

interface BackpackApiReturn {
  ugcs: Reward[];
  other: Reward[];
  badges: Reward[];
}

@injectable()
export class BackpackApi {
  axios = container.get(Api);

  async getBackpack(): Promise<BackpackApiReturn> {
    const { data } = await this.axios.get<{ data: BackpackApiReturn }>(
      "/backpack",
    );
    return data;
  }
}
