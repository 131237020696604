import container from "shared/inversify.config";
import { defineStore } from "pinia";
import { readonly, ref } from "vue";
import { OnboardingApi } from "user/api/onboarding";
import { OnboardingReward } from "user/models/initial-rewards";

export const useOnboardingStore = defineStore("onboardign", () => {
  const pendingReward = ref<OnboardingReward[]>();
  const pendingRewardIndex = ref<number>(0);

  const freespin = ref<OnboardingReward[]>();

  // establishing
  function $reset() {
    freespin.value = undefined;
    pendingReward.value = undefined;
    pendingRewardIndex.value = 0;
  }

  async function fetchOnboardingRewards() {
    const seenGifts = checkInitialGifts();
    const seenSpin = checkInitialSpin();

    if (seenSpin != "true" || seenGifts != "true") {
      const response = await container.get(OnboardingApi).getUserWelcome();
      if (response["initial-gifts"].length > 0 && seenGifts != "true") {
        pendingReward.value = response["initial-gifts"];
      }

      if (response["initial-spins"].length > 0 && seenSpin != "true") {
        freespin.value = response["initial-spins"];
      }
    } else {
      $reset();
    }
  }

  /// walkway

  // returns wether or not you shoudl continue to the next category
  function stepRewardsAndProgress() {
    pendingRewardIndex.value = pendingRewardIndex.value + 1;
    if (pendingRewardIndex.value == pendingReward.value?.length) {
      setHasSeenGifts();
      return true;
    }
    return false;
  }

  // local storage methods
  function setHasSeenGifts() {
    localStorage.setItem("initialGifts", "true");
  }
  function setHasFreeSpin() {
    localStorage.setItem("initialSpin", "true");
  }

  function checkInitialGifts() {
    return localStorage.getItem("initialGifts");
  }

  function checkInitialSpin() {
    return localStorage.getItem("initialSpin");
  }

  /// use this to gate off onboarding opperations
  function proceed() {
    const seenGifts = checkInitialGifts();
    const seenSpin = checkInitialSpin();

    return seenSpin != "true" || seenGifts != "true";
  }

  return {
    $reset,
    freespin: readonly(freespin),
    pendingRewards: readonly(pendingReward),
    pendingRewardIndex: readonly(pendingRewardIndex),
    fetchOnboardingRewards,
    stepRewardsAndProgress,
    checkInitialGifts,
    checkInitialSpin,
    setHasSeenGifts,
    setHasFreeSpin,
    proceed,
  };
});
