<script setup lang="ts">
import { useMissionStore } from "user/stores/mission";
import { Mission } from "user/models/mission";
import { useSettingStore } from "user/stores/settings";
import { useBrandStore } from "user/stores/brand";
import { Trending, TrendingCardType } from "user/models/settings";
import { UserBrand } from "user/models/brand";

const router = useRouter();
const missionStore = useMissionStore();
const brandStore = useBrandStore();
const settingsStore = useSettingStore();

const trendingBrands = ref<Record<number, UserBrand>>({});
const trendingMissions = ref<Record<number, Mission>>({});

const trendingItems = computed(() => {
  const cards = settingsStore.settings.trending_cards;
  const trending = [];
  for (const card of cards) {
    if (
      card.trending_card_type === TrendingCardType.Brands &&
      card.trending_card_id
    ) {
      const b = brandStore.getBrandsById(card.trending_card_id);
      if (b === undefined) {
        continue;
      }
      trendingBrands.value[card.trending_card_id] = b;
      trending.push(card);
    }
    if (
      card.trending_card_type === TrendingCardType.Missions &&
      card.trending_card_id
    ) {
      const m = missionStore.getMissionById(card.trending_card_id);
      if (m === undefined) {
        continue;
      }

      trendingMissions.value[card.trending_card_id] = m;
      trending.push(card);
    }
  }

  return trending;
});

function openMission(mission: Mission) {
  router.push({ query: { missionId: mission.id } });
}

function openBrand(brand: UserBrand) {
  router.push({ name: "brand", params: { id: brand.brand_id } });
}

function getBanner(item: Trending) {
  if (item.trending_card_type === TrendingCardType.Missions) {
    return trendingMissions.value[item.trending_card_id].mission_banner
      ? trendingMissions.value[item.trending_card_id].mission_banner
      : trendingMissions.value[item.trending_card_id].icon;
  }
  return trendingBrands.value[item.trending_card_id].brand_banner
    ? (trendingBrands.value[item.trending_card_id].brand_banner as string)
    : trendingBrands.value[item.trending_card_id].icon;
}
</script>

<template>
  <PageSection>
    <template #label> Trending </template>
    <div class="no-scrollbar left-0 scroll-px-5 overflow-auto">
      <div class="flex gap-x-2">
        <!-- <div class="ml-[15rem]" /> -->
        <div
          v-if="settingsStore?.settings?.trending_cards?.length > 0"
          v-for="item in trendingItems"
          :key="item.trending_card_id"
          class="active:scale-90 min-w-fit"
        >
          <div
            @click="
              item.trending_card_type === TrendingCardType.Missions
                ? openMission(trendingMissions[item.trending_card_id])
                : openBrand(trendingBrands[item.trending_card_id])
            "
          >
            <Banner :icon="getBanner(item)" scale />
          </div>
        </div>
        <!-- <div class="min-w-[15rem]" /> -->
      </div>
    </div>
  </PageSection>
</template>
