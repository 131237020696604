<script setup lang="ts">
import { Conditions, Mission } from "user/models/mission";
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import { intervalToDuration } from "date-fns";
import { useNotificationStore } from "user/stores/notifications";
import { useBrandStore } from "user/stores/brand";
import { usePlayMission } from "user/composables/usePlayMission";
import { MissionClassName } from "user/models/mission";
import { useMissionStore } from "user/stores/mission";
import container from "shared/inversify.config";
import { MissionApi } from "user/api/mission";
import CloseIcon from "admin/assets/x.svg";

const router = useRouter();
const route = useRoute();

const startQuestBtnRef = ref<HTMLButtonElement | null>(null);

const missionStore = useMissionStore();

const { playMission } = usePlayMission();

const selectedMission = computed<Mission | undefined>(() => {
  if (!route.query.missionId) {
    return undefined;
  }

  let foundedMission: Mission | undefined = missionStore.getMissionById(
    Number(route.query.missionId),
  );

  if (!foundedMission) {
    foundedMission = missionStore.getMissionByIdentifier(
      route.query.missionId as string,
    );
  }

  if (!foundedMission) {
    return undefined;
  }

  return foundedMission;
});

const brand = useBrandStore().brands?.find((brand) =>
  brand.missions.find((mis) => mis.id === selectedMission.value?.id),
);

const claimCode = ref("");
const isInProgress = ref(false);

async function click() {
  if (!selectedMission.value) {
    return;
  }
  isInProgress.value = true;

  try {
    const res = await playMission(selectedMission.value);

    if (res && res.success) {
      handleClose();
      setTimeout(
        async () => await useNotificationStore().fetchNotifications(),
        200,
      );
      await missionStore.fetchMissions();
    }
  } catch (error) {
    isInProgress.value = false;
    console.log("click ~ error:", error);
  }
}

function animateErrorHandleMission() {
  if (!startQuestBtnRef.value.buttonRef) {
    return;
  }

  startQuestBtnRef.value.buttonRef.classList.add("animate-error-shake-fixed-offset");

  setTimeout(() => {
    if (!startQuestBtnRef.value.buttonRef) {
      return;
    }
    startQuestBtnRef.value.buttonRef.classList.remove("animate-error-shake-fixed-offset");
  }, 2000);
}

async function claimUgc() {
  if (!selectedMission.value) {
    return;
  }
  if (!claimCode.value) {
    return;
  }
  try {
    await container
      .get(MissionApi)
      .claimUgc(selectedMission.value?.id, claimCode.value);

    setTimeout(
      async () => await useNotificationStore().fetchNotifications(),
      200,
    );
    await missionStore.fetchMissions();
  } catch (error) {
    console.log("claimUgc ~ error:", error);
    requestAnimationFrame(animateErrorHandleMission);
  }
}

const daysLeft = computed(() => {
  if (!selectedMission.value) {
    return;
  }
  // Calculate dates til close from mission
  const left = "";

  try {
    const starts_at = selectedMission.value.availability?.split("..")[0] ?? "";
    if (!starts_at) {
      return "";
    }
    const expires_at = selectedMission.value.availability?.split("..")[1] ?? "";

    const start = Date.parse(expires_at);
    const duration = intervalToDuration({
      end: new Date(),
      start,
    });
    if (duration.days) {
      return `Ends in ${Math.abs(duration.days)} days`;
    }

    if (duration.minutes) {
      return `Ends in ${Math.abs(duration.minutes)} minutes`;
    }

    if (duration.seconds) {
      return `Ends in ${Math.abs(duration.seconds)} seconds`;
    }
  } catch (err) {
    return left;
  }

  return "";
});

const isDisplayPlayButton = computed(() => {
  if (!selectedMission.value) {
    return false;
  }

  if (
    !selectedMission.value.conditions &&
    !missionStore.isStandardMission(selectedMission.value)
  ) {
    return false;
  }

  switch (true) {
    case selectedMission.value.class_name === MissionClassName.RobloxUpdate:
    case selectedMission.value.class_name === MissionClassName.LinkoutEvent &&
      (selectedMission.value.conditions as Conditions).url !== undefined:
    case selectedMission.value.class_name === MissionClassName.TwitterEvent &&
      (selectedMission.value.conditions as Conditions).tweet_id !== undefined:
    case selectedMission.value.class_name === MissionClassName.TwitterEvent &&
      (selectedMission.value.conditions as Conditions).user_id !== undefined:
    case selectedMission.value.class_name === MissionClassName.DiscordEvent &&
      (selectedMission.value.conditions as Conditions).guild !== undefined:
    case selectedMission.value.class_name === MissionClassName.ConnectAccount &&
      (selectedMission.value.conditions as Conditions).provider !== undefined:
      return true;
    case selectedMission.value.class_name === MissionClassName.ClaimCode:
      return true;
    case missionStore.isStandardMission(selectedMission.value):
      return true;

    default:
      return false;
  }
});

function handleClose() {
  isInProgress.value = false;
  router.replace({ query: { missionId: undefined } });
}
</script>

<template>
  <Modal
    :open="Boolean(selectedMission)"
    @close="handleClose"
    class="min-h-[calc(100vh-100rem)] md:min-h-[calc(100vh-160rem)] md:w-[620rem]"
  >
    <div class="flex flex-col h-full w-full">
      <div class="flex flex-col w-full">
        <div
          class="w-full flex px-4 py-2 rounded-t-ml relative"
          :class="{
            'bg-aquaToPurple': !selectedMission?.isCompleted,
            'bg-grayToBlack': selectedMission?.isCompleted,
          }"
        >
          <h3 class="text-[20rem]/[26rem] min-h-[26rem] font-semibold">
            {{ brand?.name || "General" }}
          </h3>

          <div
            class="absolute right-0 top-1/2 -translate-x-1/2 -translate-y-1/2 cursor-pointer p-2 bg-[#B2B2B280] rounded-full"
            @click="handleClose"
          >
            <img :src="CloseIcon" alt="Icon" />
          </div>
        </div>

        <div class="flex w-full justify-center">
          <img
            v-if="selectedMission"
            :src="
              selectedMission.mission_banner
                ? selectedMission.mission_banner
                : selectedMission.icon
            "
            alt="Banner"
            class="object-cover"
          />
        </div>
      </div>

      <div class="overflow-auto w-full grow">
        <div
          class="overflow-hidden w-full flex justify-end mb-2"
          v-if="daysLeft"
        >
          <MissionBanner
            :text="daysLeft"
            class="!text-[13rem]/[15rem] !py-[2rem] md:!py-1 md:!text-[16rem]/[20rem]"
          />
        </div>
        <div
          class="flex w-full gap-4"
          :class="{
            'mt-4': !daysLeft,
          }"
        >
          <div
            class="px-6 text-[16rem]/[20rem] md:text-[20rem]/[26rem] font-semibold"
          >
            {{ selectedMission?.name }}
          </div>
        </div>

        <div
          class="text-[13rem]/[17rem] md:text-[15rem]/[21rem] w-full px-6 [&>a]:text-blueOblivion [&>a]:underline mt-4"
          v-html="selectedMission?.description"
        />

        <div
          class="flex gap-10 w-full justify-center mt-4 px-6"
          v-if="selectedMission?.class_name === MissionClassName.ClaimCode"
        >
          <template v-if="!selectedMission.isCompleted">
            <input
              v-model="claimCode"
              class="border-2 h-[58rem] shrink-0 rounded-lg px-3 text-xl w-full"
              placeholder="Enter code"
            />
          </template>
          <template v-else>
            <div class="text-lg text-black flex w-full">
              UGC has been added to your Backpack 🎁
            </div>
          </template>
        </div>

        <div class="text-base font-bold w-full px-6 mt-4">Rewards:</div>
        <div
          class="flex gap-x-3 p-6 overflow-auto no-scrollbar max-w-[325rem] md:max-w-[600rem]"
        >
          <RewardCard
            v-for="(reward, index) in selectedMission?.rewards"
            :key="index"
            :reward="reward"
            :brand-name="brand?.name"
            :size="'sm'"
          />
        </div>
      </div>

      <div class="p-3 md:p-6 w-full flex">
        <FlatButton
          ref="startQuestBtnRef"
          v-if="isDisplayPlayButton && !selectedMission?.isCompleted"
          label="Start Quest"
          :size="ButtonSize.lg"
          :disabled="isInProgress"
          play
          borderless
          @click="
            !isInProgress &&
            selectedMission?.class_name === MissionClassName.ClaimCode
              ? claimUgc()
              : click()
          "
          class="w-full hover:scale-105 transition-all"
        />
        <FlatButton
          v-if="selectedMission?.isCompleted"
          label="Go to Backpack"
          :size="ButtonSize.md"
          borderless
          @click="router.push({ name: 'backpack-ugc' })"
          class="w-full"
        />
      </div>
    </div>
  </Modal>
</template>
