<script setup lang="ts">
import { useMarketStore } from "user/stores/marketplace";

const rewardsStore = useMarketStore();
const rewards = computed(() => rewardsStore.all);

onMounted(async () => {
  await rewardsStore.fetchMarketplace();
});
</script>

<template>
  <MarketGrid :items="rewards" />
</template>
