<script setup lang="ts">
import FlatButton, { ButtonSize } from "user/components/FlatButton.vue";
import Modal from "user/components/Modal.vue";

defineEmits<{
  confirm: [];
}>();

const open = defineModel<boolean>("open", { required: true });
</script>

<template>
  <Modal v-model:open="open" class="h-fit mx-auto">
    <div
      class="flex flex-col items-center px-[47rem] py-[50rem] text-center z-50"
    >
      <div class="pb-4 text-[30rem] font-semibold">OH NO!</div>
      <div class="line- text-[20rem] font-medium pb-4">
        The QR code failed to scan. Please try again
      </div>
      <FlatButton
        label="Retry"
        :size="ButtonSize.md"
        @click="$emit('confirm')"
      />
    </div>
  </Modal>
</template>
