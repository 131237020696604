<script setup lang="ts">
import SpinAndWin from "user/assets/spin-win.png";
import SpinText from "user/assets/spin-text.svg";
import { useSessionStore } from "user/stores/session";

const router = useRouter();
const sessionStore = useSessionStore();
</script>

<template>
  <div
    v-if="!sessionStore.isAnonymous"
    @click="router.push({ name: 'spin-the-wheel' })"
    class="absolute z-10 bottom-[-30rem] right-2.5 font-Sahar flex w-[67rem] flex-col items-center justify-center text-center align-middle text-[12rem] font-bold text-white"
  >
    <img :src="SpinAndWin" alt="" class="" />
    <img :src="SpinText" alt="" class="w-[44rem]" />
  </div>
</template>
