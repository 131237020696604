<script setup lang="ts">
import { Mission } from "user/models/mission";
import { useMissionStore } from "user/stores/mission";
import { UserBrand } from "user/models/brand";
import { useBrandStore } from "user/stores/brand";

const missionStore = useMissionStore();
const brandStore = useBrandStore();
const router = useRouter();

const props = defineProps<{
  missions: Mission[] | undefined;
}>();

const displayBrands = computed(() =>
  missionStore.categories.some((pill) => {
    return pill.name == "Brands" && pill.active;
  }),
);

const displayRewards = computed(() =>
  missionStore.categories.some((pill) => {
    return pill.name == "Rewards" && pill.active;
  }),
);

const rewardList = computed(() => {
  if (displayRewards.value && props.missions && props.missions.length) {
    const rewards = [];
    const unique: Record<string, null> = {};

    for (let index = 0; index < props.missions.length; index++) {
      const mission = props.missions[index];

      for (let index = 0; index < mission.rewards.length; index++) {
        const reward = mission.rewards[index];

        if (unique[reward.id] !== undefined) {
          continue;
        }

        const brand = useBrandStore().brands?.find((brand) =>
          brand.missions.find((mis) => mis.id === mission.id),
        );
        rewards.push({
          reward,
          mission,
          brandName: brand ? brand.name : "",
        });

        unique[reward.id] = null;
      }
    }

    return rewards;
  }

  return [];
});

function openMissionDialog(mission: Mission) {
  router.replace({ query: { missionId: mission.id } });
}

function selectBrand(brand: UserBrand) {
  router.push({ name: "brand", params: { id: brand.brand_id } });
}
</script>

<template>
  <div
    v-if="displayBrands"
    class="mb-6 mt-2 flex flex-wrap gap-y-2 gap-x-1 px-2"
  >
    <BrandBanner
      v-for="brand in brandStore.brands"
      :key="brand.brand_id"
      :brand="brand"
      @click="selectBrand(brand)"
      scale
    />
  </div>
  <div
    v-else-if="displayRewards && rewardList.length > 0"
    class="mb-6 mt-2 flex flex-wrap gap-2"
  >
    <RewardCard
      v-for="(item, index) in rewardList"
      :key="index"
      :brand-name="item.brandName"
      :reward="item.reward"
      @click="openMissionDialog(item.mission)"
    />
  </div>
  <div
    v-else-if="props.missions && props.missions.length > 0"
    class="mb-6 mt-2 flex flex-wrap justify-center gap-y-4 gap-x-1"
  >
    <MissionCard
      v-for="mission in props.missions"
      :key="mission.id"
      :mission="mission"
      @click="openMissionDialog(mission)"
      scale
      turn-on-zoom-out-animation
    />
  </div>
  <div v-else class="mb-6 flex flex-wrap gap-y-2 gap-x-1 px-2">
    <NoMissions class="col-span-3" />
  </div>
</template>
