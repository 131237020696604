<script setup lang="ts">
import STWBanner from "user/assets/stw/STW-banner.png";
import BuyModal from "user/components/BuyModal.vue";
import { useBuyStore } from "user/stores/buy";
import { useMarketStore } from "user/stores/marketplace";
const rewardsStore = useMarketStore();

const banners = [STWBanner];

const stwRewards = computed(() => rewardsStore.spins);
const buyStore = useBuyStore();

onMounted(async () => {
  await rewardsStore.fetchMarketplace();
});
</script>

<template>
  <STWLayout>
    <div
      class="group relative overflow-scroll whitespace-nowrap snap-x no-scrollbar mt-8 px-4"
    >
      <div
        v-if="banners.length > 1"
        v-for="bannerItem in banners"
        class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max"
      >
        <img
          :src="bannerItem"
          alt="Banner"
          class="rounded-ml mx-2 inline overflow-clip"
        />
      </div>
      <div
        v-else
        class="animate-slide-left-infinite group-hover:animation-pause flex justify-center"
      >
        <img
          :src="banners[0]"
          alt="Banner"
          class="rounded-ml mx-2 inline overflow-clip"
        />
      </div>
    </div>

    <div
      class="text-[12rem]/[24rem] md:text-[30rem]/[60rem] text-white font-semibold w-full px-6 mt-4"
    >
      BUY SPINS
    </div>

    <TabBar class="left-0 top-0 z-10 mt-6 mx-6 overflow-x-visible">
      <TabBarItem
        to="/spin-the-wheel/buy"
        label="SPINS"
        class="text-white border-b-white"
        backpack
      />
    </TabBar>

    <div class="flex gap-x-3 p-6 overflow-auto no-scrollbar max-w-full">
      <MarketListCard
        v-for="(reward, index) in stwRewards"
        :key="index"
        :item="reward"
        class="min-w-[200rem] max-w-[200rem]"
        type="stw"
        :discount="0.2"
        @click="buyStore.buyItem(reward)"
      />
    </div>
    <BuyModal />
  </STWLayout>
</template>
