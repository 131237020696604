<script setup lang="ts">
const props = defineProps<{
  label: string;
  to: string | undefined;
  badge?: number;
  image?: string;
  inactiveImage: string;
  block?: boolean;
  anonUser?: boolean;
}>();

const emit = defineEmits<{
  block: [];
}>();

function routeOrOpenDialog(navigate: () => void) {
  if (props.anonUser && props.block) {
    emit("block");
  } else {
    navigate();
  }
}

const routePrefix = computed(() => props.to?.split("/")[1]);
</script>

<template>
  <RouterLink v-slot="{ href, navigate }" custom :to="to as string">
    <a
      :href="href"
      class="relative flex w-max items-center justify-center text-white text-base gap-5 lg:gap-10 h-[60rem]"
      @click.prevent="routeOrOpenDialog(navigate)"
    >
      <div
        class="flex w-[60rem] md:w-[30rem] lg:w-[60rem] flex-col items-center"
      >
        <img
          v-if="!$route.path.includes(routePrefix!)"
          :src="inactiveImage"
          :alt="`navigate to ${label}`"
          class="h-[25rem] w-[25rem] object-contain"
        />
        <img
          v-else
          :src="image"
          :alt="`navigate to ${label}`"
          class="h-[25rem] w-[25rem] object-contain"
        />
      </div>
      <div
        class="text-[16rem]/[28rem] lg:text-[23rem]/[35rem] font-bold hidden md:block"
        :class="{
          'opacity-30': !$route.path.includes(routePrefix!),
        }"
      >
        {{ label }}
      </div>
      <div
        v-if="badge"
        class="absolute -top-0.5 right-1 flex h-[19rem] w-[19rem] items-center justify-center rounded-full bg-nativeBerry text-xs font-[900] text-white"
      >
        {{ badge }}
      </div>
    </a>
  </RouterLink>
</template>
