import { injectable } from "inversify";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";
import { Referral } from "user/models/referrals";

@injectable()
export class ReferralApi {
  axios = container.get(Api);

  async getReferrals(): Promise<Referral[]> {
    const { data } = await this.axios.get<Referral[]>('/get_referrals');
    return data.referrals;
  }

  async applyReferralCode(referralCode: string) {
    await this.axios.post(`apply_referral?referral_code=${referralCode}`);
  }
}
