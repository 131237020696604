<script setup lang="ts">
import "reflect-metadata";
import "@aarsteinmedia/dotlottie-player";
import "./poppins.js";
import loginBg from "user/assets/login-bg@2x.png";
import { onMounted } from "vue";
import { prefetchImages } from "user/utils/image-prefetch";
import { RouterView } from "vue-router";
import { useSettingStore } from "./stores/settings.js";
import { intervalToDuration } from "date-fns";
import { useSessionStore } from "./stores/session.js";
import { storeToRefs } from "pinia";
import { useStorage } from "@vueuse/core";

const settingsStore = useSettingStore();
const sessionStore = useSessionStore();
const { settings } = storeToRefs(settingsStore);
const { currentUser, isAnonymous } = storeToRefs(sessionStore);

const ts_when_ad_showed = useStorage("ad_ts_showed", ""); // returns Ref<boolean>

const showAd = computed({
  get() {
    if (
      !settings.value.ad_button_color ||
      !settings.value.ad_button_link ||
      !settings.value.ad_button_text ||
      !settings.value.ad_poster_image ||
      !settings.value.ad_repeat_interval_hours
    ) {
      return false;
    }
    if (isAnonymous.value || !currentUser.value) {
      return false;
    }
    if (!ts_when_ad_showed.value) {
      return true;
    }

    const duration = intervalToDuration({
      start: Number(ts_when_ad_showed.value),
      end: Date.now(),
    });

    const intervalCheck = settingsStore.settings.ad_repeat_interval_hours
      ? settingsStore.settings.ad_repeat_interval_hours
      : 8;

    if (duration.hours && Math.abs(duration.hours) >= intervalCheck) {
      return true;
    }

    return false;
  },
  set() {
    ts_when_ad_showed.value = Date.now().toString();
  },
});

onMounted(() => {
  settingsStore.load();
  prefetchImages([loginBg]);
  sessionStore.tryFetchCurrentUser();
});
</script>

<template>
  <div class="flex min-h-screen flex-col">
    <RouterView />
    <OnboardingHandler />
  </div>
  <AdModal v-model:showAd="showAd" />
  <MissionModal />
</template>

<style>
:root {
  font-size: min(calc(100vw / 390), 1px);
}

* {
  -webkit-tap-highlight-color: transparent;
}
</style>
