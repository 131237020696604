<script setup lang="ts">
import { useSettingStore } from "user/stores/settings";
import { useBrandStore } from "user/stores/brand";
import { IFeaturedBanner } from "user/models/settings";
const brandStore = useBrandStore();
const settingsStore = useSettingStore();
const router = useRouter();

function openBannerLink(item: IFeaturedBanner) {
  if (item.featured_banner_brand_id) {
    const brand = brandStore.getBrandsById(item.featured_banner_brand_id);

    if (!brand) {
      return;
    }

    router.push({ name: "brand", params: { id: brand.brand_id } });
  } else {
    window.open(item.featured_banner_url, "_blank");
  }
}

function imageUrl(item: IFeaturedBanner) {
  if (item?.featured_banner_image) {
    return item?.featured_banner_image;
  }

  if (item.featured_banner_brand_id) {
    const brand = brandStore.getBrandsById(item.featured_banner_brand_id);

    if (!brand) {
      return;
    }

    return brand.icon;
  }
}
</script>

<template>
  <PageSection>
    <div
      class="group relative overflow-scroll whitespace-nowrap snap-x no-scrollbar"
    >
      <div
        v-for="bannerItem in settingsStore.settings.featured_banners"
        class="inline-block animate-slide-left-infinite group-hover:animation-pause w-max"
      >
        <Banner
          v-if="imageUrl(bannerItem)"
          :icon="imageUrl(bannerItem) as string"
          @click="openBannerLink(bannerItem)"
          class="mx-1 h-[200rem] md:h-auto"
        />
      </div>
    </div>
  </PageSection>
</template>
