<script setup lang="ts">
import { useRouter } from "vue-router";
import container from "shared/inversify.config";
import { SessionApi } from "user/api/session";
import { useSessionStore } from "user/stores/session";
import logoutImage from "/user/assets/sign_out.png";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

const router = useRouter();
const open = defineModel<boolean>("open", { required: true });
const sessionStore = useSessionStore();
const userAnonymous = computed(() => sessionStore.isAnonymous);

const title = computed(() => {
  if (userAnonymous.value) {
    return "Save Your Progress";
  } else {
    return "WE'RE SAD TO SEE YOU GO";
  }
});

const description = computed(() => {
  if (userAnonymous.value) {
    return "Set up your account before you go! You will lose all of your progress if you sign out now";
  } else {
    return "Are you sure you wish to sign out?";
  }
});

const confirmLabel = computed(() => {
  if (userAnonymous.value) {
    return "Sign Up";
  } else {
    return "Sign Out";
  }
});

async function logOutOrSignUp() {
  open.value = false;

  if (!userAnonymous.value) {
    await sessionStore.destroySession();
    sessionStore.deleteCookie();
  }

  router.push("/login");
}
</script>

<template>
  <TransitionRoot :show="open">
    <Dialog as="div" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 z-50 bg-black/50" />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-[opacity,transform]"
        enter-from="opacity-0 translate-y-[100rem]"
        enter-to="opacity-100 translate-y-0"
        leave="duration-200 ease-in transition-[opacity,transform]"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          as="div"
          class="fixed inset-0 md:translate-x-[207rem] w-fit z-50 m-auto h-max rounded-ml flex flex-col items-center bg-white pt-[40rem] py-[24rem] px-[20rem] text-center shadow-logout"
        >
          <div>
            <img :src="logoutImage" alt="Image" />
          </div>

          <DialogTitle
            class="whitespace-pre-line text-[24rem]/[26rem] font-semibold pt-[30rem]"
          >
            {{ title }}
          </DialogTitle>

          <DialogDescription
            class="mt-4 whitespace-pre-line text-center text-[18rem]/[26rem] max-w-[304rem]"
          >
            {{ description }}
          </DialogDescription>

          <div class="flex gap-x-4 pt-[30rem]">
            <ButtonClient
              kind="danger"
              class="p-[20rem] !w-fit md:!w-[236rem] text-[20rem] font-semibold"
              @click="logOutOrSignUp"
            >
              {{ confirmLabel }}
            </ButtonClient>
            <ButtonClient
              class="p-[20rem] !w-fit md:!w-[236rem] text-[20rem] border-2 border-black font-semibold"
              @click="open = false"
            >
              Cancel
            </ButtonClient>
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
