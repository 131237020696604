import { injectable } from "inversify";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";
import { GlobalSettings } from "user/models/settings";

@injectable()
export class SettingsApi {
  axios = container.get(Api);

  async getSettings(): Promise<GlobalSettings> {
    try {
      const { data } = await this.axios.get<{
        data: GlobalSettings;
      }>(`/settings`);
      return data;
    } catch (e) {
      return {} as GlobalSettings;
    }
  }
}
