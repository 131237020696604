<script setup lang="ts">
import type { DotLottiePlayer } from "@aarsteinmedia/dotlottie-player";

import lootboxRed from "user/assets/lotties/lootbox/Red Lootbox animation.json";
import lootboxGreen from "user/assets/lotties/lootbox/Green Lootbox animation.json";
import lootboxBlue from "user/assets/lotties/lootbox/Blue Lootbox animation.json";
import lootboxPurp from "user/assets/lotties/lootbox/Purple Lootbox animation.json";
import lootboxYellow from "user/assets/lotties/lootbox/Yellow Lootbox animation.json";
import lootboxBlack from "user/assets/lotties/lootbox/Black Lootbox animation.json";
import lootboxMystery from "user/assets/lotties/lootbox/Mystery Box-animation.json";
import { Rarity, type Reward, isStarsLootbox } from "user/models/items";
import { OnboardingReward } from "user/models/initial-rewards";

const props = defineProps<{
  activate: boolean;
  prize: Reward | OnboardingReward;
}>();

const playerRef = ref<DotLottiePlayer>();
const isSet = ref(false);
const frame = ref(0);

const emit = defineEmits<{
  done: [];
}>();

onMounted(() => {
  playerRef.value?.addEventListener("stop", () => emit("done"));

  playerRef.value?.addEventListener("frame", () => {
    frame.value++;
  });

  playerRef.value?.addEventListener("play", () => {
    if (!isSet.value) {
      setTimeout(() => {
        playerRef.value?.pause();
        isSet.value = true;
      }, 700);
    } else {
      setTimeout(() => {
        playerRef.value?.stop();
      }, 3000);
    }
  });
  setTimeout(() => playerRef.value?.play(), 100);
});

const animation = computed(() => {
  if (props.prize.is_mystery_box_reward) {
    return lootboxMystery;
  }
  if (isStarsLootbox(props.prize)) {
    return lootboxYellow;
  }
  switch (props.prize.rarity) {
    case Rarity.Mystical:
      return lootboxMystery;
    case Rarity.epic:
      return lootboxBlack;
    case Rarity.legendary:
      return lootboxPurp;
    case Rarity.rare:
      return lootboxBlue;
    case Rarity.special:
      return lootboxGreen;
    case Rarity.stars:
      return lootboxYellow;
    case Rarity.default:
    default:
      return lootboxYellow;
  }
});

watch(
  () => props.activate,
  (newValue, oldValue) => {
    if (!oldValue && newValue) {
      playerRef.value?.play();
    }
  },
);

watch(
  () => animation.value,
  (newValue, oldValue) => {
    if (!oldValue && newValue) {
      playerRef.value?.load(animation.value.toString());
    }
  },
);
</script>
<template>
  <dotlottie-player
    ref="playerRef"
    :src="animation"
    autoplay
    class="z-50 scale-130"
  />
</template>
<!-- prop = animations start
on start triggher animation, wtach for when it ends, when it ends emit (next)
on next emit, hide this whole element and kick off other component, Prize Animations
-->
