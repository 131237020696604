<script setup lang="ts">
import container from "shared/inversify.config";
import FunButton, { ButtonSize } from "user/components/FlatButton.vue";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { FeedbackApi } from "user/api/feedback";
import { useSessionStore } from "user/stores/session";
import { Rating, Satisfaction } from "user/models/feedback";
import FunInput from "user/components/FunInput.vue";

const emit = defineEmits<{
  submit: [];
}>();

const router = useRouter();
const session = useSessionStore();
const subject = ref("");
const name = ref(session.currentUser?.name);
const email = ref(session.currentUser?.email);
const message = ref("");

const incompleteFeedback = ref(false);

async function onSubmit() {
  if (
    subject.value == null ||
    name.value == null ||
    email.value == null ||
    message.value == null
  ) {
    incompleteFeedback.value = true;
    return;
  }
  await container.get(FeedbackApi).submitFeedback({
    subject: subject.value,
    email: email.value,
    name: name.value,
    body: message.value,
    attachment: undefined,
  });
  emit("submit");
}
</script>

<template>
  <form @submit.prevent="onSubmit">
    <div
      class="z-10 flex w-[390rem] flex-col items-center gap-y-5 rounded-[20rem] px-5 pb-[50rem] pt-5 text-left text-[15rem] font-semibold"
    >
      <label for="subject" class="self-start">Subject</label>
      <input
        v-model="subject"
        id="subject"
        placeholder="Subject of feedback"
        type="text"
        required
        class="w-[100%] p-4 shadow-referral rounded-[4rem]"
      />
      <label for="subject" class="self-start">Name</label>
      <input
        v-model="name"
        id="name"
        placeholder="Name"
        type="text"
        required
        disabled
        class="w-[100%] p-4 shadow-referral rounded-[4rem]"
      />
      <label for="subject" class="self-start">Email</label>
      <input
        v-model="email"
        id="email"
        placeholder="Email"
        type="text"
        disabled
        required
        class="w-[100%] p-4 shadow-referral rounded-[4rem]"
      />
      <label for="subject" class="self-start">Message</label>
      <textarea
        v-model="message"
        class="h-[111rem] w-full rounded-[4rem] p-4 font-normal shadow-referral"
        placeholder="Write your feedback here"
      />
    </div>
    <div class="mb-9 mt-[27rem] flex w-full flex-col items-center gap-y-[6rem]">
      <FunButton
        label="Submit"
        type="submit"
        kind="primary"
        class="w-full max-w-[211rem]"
        :size="ButtonSize.lg"
      />
      <FunButton
        label="Cancel"
        type="button"
        kind="alternate"
        class="w-full max-w-[211rem]"
        :size="ButtonSize.lg"
        @click="router.back()"
      />
    </div>
  </form>
</template>
