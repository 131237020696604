<template>
  <Layout type="feedback">
    <ActionBar title back>
      <div class="font-bold">Settings</div>
    </ActionBar>
    <div class="my-6 text-center">
      <div class="text-xl font-semibold">Feedback</div>
    </div>
    <div class="flex justify-center">
      <FeedbackBlock @submit="$router.push('/profile')" />
    </div>
  </Layout>
</template>
