<script setup lang="ts">
import { useTimeoutFn } from "@vueuse/core";

const props = withDefaults(
  defineProps<{
    label: string;
    type?: "button" | "submit";
    kind?: "primary" | "alternate";
    onClick?: () => Promise<void> | void;
  }>(),
  {
    type: "button",
    kind: "primary",
  },
);

const el = ref<HTMLButtonElement>();
const busy = ref(false);

function delayClick(event: Event) {
  // an untrusted event means that it was manually sent
  // from say a `.click()` invocation
  if (!event.isTrusted) return;

  event.preventDefault();
  event.stopPropagation();

  // slow your clicks
  if (busy.value) return;

  busy.value = true;

  useTimeoutFn(async () => {
    if (props.onClick != null) {
      await props.onClick?.();
    } else if (props.type === "submit") {
      // trigger the submit handler of a <form>
      el.value?.click();
    }

    useTimeoutFn(() => {
      // after another 100ms, remove the busy status
      busy.value = false;
    }, 100);
  }, 100);
}
</script>

<template>
  <button
    ref="el"
    class="group relative flex h-[51rem] shrink-0 appearance-none rounded-xl outline-none"
    :type="type"
    @click="delayClick"
  >
    <span
      class="absolute bottom-0 left-0 right-0 h-[50%] w-full rounded-xl ring-[2rem] ring-inset ring-black"
      :class="{
        'bg-100mph ': kind === 'primary',
        'bg-black': kind === 'alternate',
      }"
    />

    <span
      class="relative flex h-11 w-full items-center justify-center rounded-xl text-[15rem]/[20rem] font-semibold ring-[2rem] ring-inset ring-black duration-[100ms] ease-in-out group-active:translate-y-[4rem]"
      :class="{
        'bg-flirtySalmon text-white': kind === 'primary',
        'bg-white text-black': kind === 'alternate',
      }"
    >
      {{ label }}
    </span>
  </button>
</template>
