import QrScanner from "qr-scanner";
import { onUnmounted, unref, watch, type Ref } from "vue";

export function useQrScanner(
  element: Ref<HTMLVideoElement | undefined> | HTMLVideoElement,
  onDecode: (value: string) => void,
) {
  let scanner: QrScanner | null = null;

  watch(
    () => unref(element),
    (newValue) => {
      if (scanner) {
        void stop();
      }

      if (newValue) {
        scanner = new QrScanner(
          newValue,
          (result: QrScanner.ScanResult) => {
            onDecode(result.data);
          },
          {
            preferredCamera: "environment",
            maxScansPerSecond: 5,
          },
        );

        void scanner.start();
      }
    },
    {
      immediate: true,
    },
  );

  async function stop() {
    try {
      await scanner?.stop();
    } catch (error) {
      // fully ignore any error to stop
      console.warn(error);
    }
  }

  onUnmounted(() => {
    void stop();
  });

  return {
    stop,
  };
}
