import { AxiosResponse } from "axios";
import { injectable } from "inversify";
import { Api } from "shared/api/index";
import container from "shared/inversify.config";

@injectable()
export class MerchApi {
  axios = container.get(Api);

  async redeemMerch(
    merchId: number,
    username: string,
  ): Promise<IRedeemMerchResponse> {
    const res = await this.axios.post<AxiosResponse>("/add_merch_user", {
      id: merchId,
      username,
    });

    if (res.status !== 200) {
      return { success: false, message: res.data };
    }

    return { success: true, message: res.data };
  }
}

interface IRedeemMerchResponse {
  success: boolean;
  message: string;
}
