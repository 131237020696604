<script setup lang="ts">
import { ButtonSize } from "user/components/FlatButton.vue";
import { Currency } from "user/models/currency";
import { OnboardingReward } from "user/models/initial-rewards";
import {
  isGems,
  isRobux,
  isSpins,
  isStars,
  isUGC,
  Reward,
} from "user/models/items";

const props = defineProps<{
  prize: Reward | OnboardingReward;
  prizeAnimationActive: boolean;
  done: boolean;
  isMarket?: boolean;
  isOnboarding?: boolean;
  sellingPrice?: Currency;
}>();

const emit = defineEmits<{
  goToHome: [];
  goToBackpack: [];
  goToMarket: [];
  setDone: [];
  continue: [];
  buyAgain: [];
}>();

const header = computed(() => {
  return isUGC(props.prize) ? "YIPPIE!" : "HOORAY!";
});

const isSoldForGems = computed(() => {
  return props.sellingPrice?.gems ? true : false;
});

const gemsPrice = computed(() => {
  return props.sellingPrice?.gems;
});

const starsPrice = computed(() => {
  return props.sellingPrice?.stars;
});

const description = computed(() => {
  switch (true) {
    case isUGC(props.prize):
      return "UGC has been added to your Backpack";
    case isRobux(props.prize):
      if (props.isMarket) {
        if (props.prize.marketplace_quantity) {
          return `You redeem ${props.prize.marketplace_quantity} Robux with 
          ${isSoldForGems.value ? `${gemsPrice.value}` : `${starsPrice.value}`}
          ${isSoldForGems.value ? "GEMs" : "STARs"}! Check your backpack to claim`;
        } else {
          if (props.prize.amount_allocated) {
            return `You won ${props.prize.amount_allocated} Robux! Check your backpack to claim`;
          } else {
            return "You won Robux! Check your backpack to claim";
          }
        }
      } else {
        if (props.prize.amount_allocated) {
          return `You won ${props.prize.amount_allocated} Robux! Check your backpack to claim`;
        } else {
          return "You won Robux! Check your backpack to claim";
        }
      }

    case isStars(props.prize):
      if (props.isMarket) {
        if (props.prize.marketplace_quantity) {
          `You redeem ${props.prize.marketplace_quantity} STARs with 
          ${isSoldForGems.value ? `${gemsPrice.value}` : `${starsPrice.value}`}
          ${isSoldForGems.value ? "GEMs" : "STARs"}! Use them in the Marketplace`;
        } else {
          if (props.prize.amount_allocated) {
            return `You won ${props.prize.amount_allocated} STARs! Use them in the Marketplace`;
          } else {
            return "You won STARs!";
          }
        }
      } else {
        if (props.prize.amount_allocated) {
          return `You won ${props.prize.amount_allocated} STARs! Use them in the Marketplace`;
        } else {
          return "You won STARs!";
        }
      }

    case isGems(props.prize):
      if (props.isMarket) {
        if (props.prize.marketplace_quantity) {
          return `You redeem ${props.prize.marketplace_quantity} GEMs with 
          ${isSoldForGems.value ? `${gemsPrice.value}` : `${starsPrice.value}`}
          ${isSoldForGems.value ? "GEMs" : "STARs"}! Use them in the Marketplace`;
        } else {
          if (props.prize.amount_allocated) {
            return `You won ${props.prize.amount_allocated} GEMs! Use them in the Marketplace`;
          } else {
            return "You won GEMs! Use them in the Marketplace";
          }
        }
      } else {
        if (props.prize.amount_allocated) {
          return `You won ${props.prize.amount_allocated} GEMs! Use them in the Marketplace`;
        } else {
          return "You won GEMs! Use them in the Marketplace";
        }
      }

    default:
      if (props.isMarket) {
        if (props.prize.marketplace_quantity) {
          return `You redeem ${props.prize.marketplace_quantity} ${props.prize.name} with ${isSoldForGems.value ? `${gemsPrice.value}` : `${starsPrice.value}`}
          ${isSoldForGems.value ? "GEMs" : "STARs"}`;
        } else {
          return `You redeem ${props.prize.name}`;
        }
      } else {
        return `You won ${props.prize.name}`;
      }
  }
});

function linkout() {
  if (props.prize?.game != null) {
    window.open(props.prize?.game, "_blank");
  }
}
</script>

<template>
  <div class="min-h-[190rem]">
    <ReceiveModalHeader
      :amount_allocated="prize.amount_allocated"
      :class="{
        hidden: !done,
      }"
    >
      {{ header }}
    </ReceiveModalHeader>
  </div>

  <div
    class="flex justify-center items-center grow w-full min-h-[calc(100%-380rem)]"
  >
    <PrizeAnimation
      :asset="prize.icon"
      :open="prizeAnimationActive"
      @done="emit('setDone')"
    />
  </div>

  <div class="flex flex-col justify-center items-center gap-4 min-h-[190rem]">
    <div
      :class="{
        hidden: !done,
      }"
      class="text-center text-black text-[20rem]/[28rem] font-medium"
    >
      {{ description }}
    </div>

    <div
      class="flex flex-col gap-2"
      :class="{
        hidden: !done,
      }"
    >
      <FlatButton
        v-if="isSpins(prize)"
        label="Buy again"
        @click="emit('buyAgain')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
      />
      <FlatButton
        v-else-if="isOnboarding"
        label="Continue"
        @click="emit('continue')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
      />
      <FlatButton
        v-else-if="isMarket && isUGC(prize) && prize.game"
        label="Open Roblox"
        @click="linkout"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
      />
      <FlatButton
        v-else-if="isRobux(prize) || isGems(prize) || isStars(prize)"
        label="Go to Marketplace"
        @click="emit('goToMarket')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
      />
      <FlatButton
        v-else
        label="Go to Backpack"
        @click="emit('goToBackpack')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
      />
      <FlatButton
        v-if="!isOnboarding"
        label="Back to Home"
        @click="emit('goToHome')"
        :size="ButtonSize.lg"
        class="z-[60] shadow-home"
        hollow
      />
    </div>
  </div>
</template>
