<script setup lang="ts">
import SettingsWhiteIcon from "user/assets/icons/settings-icon-white.svg";
import { useSessionStore } from "user/stores/session";

const sessionStore = useSessionStore();
const user = computed(() => sessionStore.currentUser);
</script>

<template>
  <ActionBar>
    <RouterLink to="/settings" class="size-[36rem]">
      <img
        :src="SettingsWhiteIcon"
        alt="Settings icon"
        class="flex object-contain h-full"
      />
    </RouterLink>
  </ActionBar>

  <ProfileInfo class="mt-[29rem]" :name="user?.name" :email="user?.email" />
</template>
