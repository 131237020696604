import { injectable } from "inversify";
import container from "shared/inversify.config";
import { Api } from "shared/api/index";
import { Reward } from "user/models/items";

@injectable()
export class SpinApi {
  axios = container.get(Api);

  async spinwheel(): Promise<Reward> {
    const { data } = await this.axios.post<{ data: Reward }>("/spin");
    return data;
  }
}
