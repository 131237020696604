<script setup lang="ts">
import { useTimeoutFn } from "@vueuse/core";

const props = defineProps<{
  onClick?: () => Promise<void> | void;
  color?: string;
}>();

const el = ref<HTMLButtonElement>();
const busy = ref(false);

function delayClick(event: Event) {
  if (!event.isTrusted) return;

  event.preventDefault();
  event.stopPropagation();

  if (busy.value) return;

  busy.value = true;

  useTimeoutFn(async () => {
    if (props.onClick != null) {
      await props.onClick?.();
    }

    useTimeoutFn(() => {
      busy.value = false;
    }, 100);
  }, 100);
}
</script>

<template>
  <button
    ref="el"
    class="group relative flex h-[51rem] shrink-0 appearance-none rounded-full outline-none"
    type="button"
    @click="delayClick"
  >
    <span
      class="absolute bottom-0 left-0 bg-black right-0 h-full w-full rounded-full ring-[2rem] ring-inset ring-black"
    />

    <span
      class="relative uppercase leading-[43rem] font-bold flex h-11 px-4 w-full text-black items-center justify-center rounded-full text-[15rem]/[20rem] ring-[2rem] ring-inset ring-black duration-[100ms] ease-in-out group-active:translate-y-[4rem]"
      :class="{
        'bg-adAqua': !color,
      }"
      :style="{
        backgroundColor: color,
      }"
    >
      <slot />
    </span>
  </button>
</template>
