<script lang="ts" setup>
import { UserBrand } from "user/models/brand";
import { useBrandStore } from "user/stores/brand";

const brandStore = useBrandStore();
const router = useRouter();

function openBrand(brand: UserBrand) {
  router.push({ name: "brand", params: { id: brand.brand_id } });
}
</script>
<template>
  <section class="py-4">
    <div
      class="group relative overflow-scroll whitespace-nowrap snap-x no-scrollbar"
    >
      <div
        class="animate-slide-left-infinite group-hover:animation-pause inline-block w-max"
      >
        <img
          :src="brand.icon"
          class="mx-2 inline md:h-[188rem] md:w-[564rem] h-[122rem] w-[366rem] overflow-clip rounded-md"
          alt="brand"
          v-for="brand in brandStore.brands"
          @click="openBrand(brand)"
        />
      </div>
    </div>
  </section>
</template>
