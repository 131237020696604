<script setup lang="ts">
import { useMissionStore } from "user/stores/mission";
import { Mission, MissionFilter } from "user/models/mission";
import { useBrandStore } from "user/stores/brand";
import { UserBrand } from "user/models/brand";

const missionStore = useMissionStore();
const brandStore = useBrandStore();

const getMissionStatusFilter = (val: string) => {
  if (val === "in-progress") {
    return MissionFilter.Active;
  }
  if (val === "completed") {
    return MissionFilter.Completed;
  }
  return MissionFilter.Available;
};

const missions = computed(() => {
  return missionStore.getMissions(status.value);
});

const allMission = computed(() => {
  const active = missionStore.getMissions(MissionFilter.Active) ?? [];
  const progress = missionStore.getMissions(MissionFilter.Available) ?? [];

  return [...active, ...progress];
});

const router = useRouter();
const route = useRoute();
const type = ref(route.params.type);
const status = ref(getMissionStatusFilter(route.params.status as string));

const rewardList = computed(() => {
  if (type.value === "rewards" && allMission.value?.length) {
    const rewards = [];
    const unique: Record<string, null> = {};

    for (let index = 0; index < allMission.value.length; index++) {
      const mission = allMission.value[index];

      for (let index = 0; index < mission.rewards.length; index++) {
        const reward = mission.rewards[index];

        if (unique[reward.id] !== undefined) {
          continue;
        }

        const brand = useBrandStore().brands?.find((brand) =>
          brand.missions.find((mis) => mis.id === mission.id),
        );
        rewards.push({
          reward,
          mission,
          brandName: brand ? brand.name : "",
        });

        unique[reward.id] = null;
      }
    }

    return rewards;
  }

  return [];
});

function openMissionDialog(mission: Mission) {
  router.push({ query: { missionId: mission.id } });
}

function selectBrand(brand: UserBrand) {
  router.push({ name: "brand", params: { id: brand.brand_id } });
}

watch(
  () => route.params.type,
  (val) => {
    type.value = val;
  },
);

watch(
  () => route.params.status,
  (val) => {
    status.value = getMissionStatusFilter(val as string);
  },
);
</script>

<template>
  <Layout>
    <HeaderBar />

    <div class="flex flex-col">
      <div class="bg white z-20 flex justify-center flex-col">
        <div class="text-Black text-center align-middle text-3xl font-semibold">
          Quests
        </div>
        <div class="px-5">
          <MarketBalance />
        </div>
      </div>
      <!-- Carosoul goes here -->
      <BrandCarousel />

      <div
        class="mb-6 sm:hidden md:block text-left hidden text-3xl font-semibold text-black px-5"
      >
        Quests
      </div>
      <!-- filters/notfics go here, this should be a filter that can be applied to the store, not a navigation -->
      <MissionPills />
      <TabBar class="mt-2 mx-5" v-show="type === 'missions'">
        <TabBarItem
          :to="{
            name: 'missions',
            params: { status: 'available', type },
          }"
          label="Available"
        />
        <TabBarItem
          :to="{
            name: 'missions',
            params: { status: 'in-progress', type },
          }"
          label="In Progress"
        />
        <TabBarItem
          :to="{
            name: 'missions',
            params: { status: 'completed', type },
          }"
          label="Completed"
        />
      </TabBar>

      <div class="flex flex-col pb-6 px-4 pt-2">
        <div
          v-if="type === 'brands'"
          class="mb-6 mt-2 flex flex-wrap gap-y-2 gap-x-1 px-2"
        >
          <BrandBanner
            v-for="brand in brandStore.brands"
            :key="brand.brand_id"
            :brand="brand"
            @click="selectBrand(brand)"
            scale
          />
        </div>
        <div
          v-else-if="type === 'rewards' && rewardList.length > 0"
          class="mb-6 mt-2 flex flex-wrap gap-2"
        >
          <RewardCard
            v-for="(item, index) in rewardList"
            :key="index"
            :brand-name="item.brandName"
            :reward="item.reward"
            @click="openMissionDialog(item.mission)"
          />
        </div>
        <div
          v-else-if="missions && missions.length > 0"
          class="mb-6 mt-2 flex justify-center flex-wrap gap-y-4 gap-x-1"
        >
          <MissionCard
            v-for="mission in missions"
            :key="mission.id"
            :mission="mission"
            @click="openMissionDialog(mission)"
            scale
            turn-on-zoom-out-animation
          />
        </div>
        <div v-else class="mb-6 flex flex-wrap gap-y-2 gap-x-1 px-2">
          <NoMissions class="col-span-3" />
        </div>
      </div>
    </div>
  </Layout>
</template>
