<script setup lang="ts">
import { useRouter } from "vue-router";
import { ref } from "vue";
import termsHtml from "user/assets/terms-of-use.html?raw";
import privacyHtml from "user/assets/privacy-policy.html?raw";
import LegalDialog from "user/components/LegalDialog.vue";
import PlaysideLogo from "user/assets/playside-main-logo.png";

const router = useRouter();
const termsDialogOpen = ref(false);
const privacyDialogHtml = ref(false);

function onSubmit() {
  void router.push("/home");
}
</script>

<template>
  <form class="relative z-10 flex flex-col px-4" @submit.prevent="onSubmit">
    <img :src="PlaysideLogo" alt="playside" />
    <OauthLogin />

    <div class="flex items-center gap-x-2 py-[20rem]">
      <div class="h-px flex-1 bg-white" />

      <!-- <div class="text-[15rem]/[20rem] font-semibold text-white">or</div> -->
    </div>
    <!--
    <div class="flex flex-col items-center justify-center gap-x-2.5">
      <FunInput
        id="frm-email"
        v-model="email"
        placeholder="enter email address"
        input-class="placeholder-offBroadway w-[306rem] !h-[47rem]"
        type="email"
        required
        autocomplete="email"
      />
    </div> -->

    <div
      class="px-6 text-center text-[11rem]/[13rem] text-white [text-shadow:0px_4px_4px_rgba(0,0,0,0.25)]"
    >
      By creating an account, you agree to our
      <span class="cursor-pointer underline" @click="privacyDialogHtml = true">
        Privacy Policy
      </span>
      &
      <span class="cursor-pointer underline" @click="termsDialogOpen = true">
        Terms and Conditions
      </span>
    </div>

    <FlatButton
      label="Sign In Later"
      type="submit"
      @click="onSubmit"
      class="self-center mt-10"
    />

    <LegalDialog
      v-model:open="termsDialogOpen"
      class="bg-bellflower"
      title="Terms & Conditions"
      :html="termsHtml"
    />

    <LegalDialog
      v-model:open="privacyDialogHtml"
      class="bg-bellflower"
      title="Privacy Policy"
      :html="privacyHtml"
    />
  </form>
</template>
