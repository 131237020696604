import container from "shared/inversify.config";
import { injectable } from "inversify";
import { Notification } from "user/models/notifications";
import { Api } from "shared/api/index";

@injectable()
export class NotificationApi {
  axios = container.get(Api);

  async getNotifications(): Promise<Notification[]> {
    const response = await this.axios.get<{ data: Notification[] }>(
      "/notifications",
    );
    return response.data;
  }
}
