import { defineStore } from "pinia";
import { Reward } from "user/models/items";

export const useBuyStore = defineStore("buy", () => {
  const selectedItem = ref<Reward | null>(null);

  function $reset() {
    selectedItem.value = null;
  }

  async function buyItem(item: Reward) {
    selectedItem.value = item;
  }

  return {
    $reset,
    selectedItem,
    buyItem,
  };
});
