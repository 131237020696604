<template>
  <Layout>
    <HeaderBar />

    <div class="flex flex-col px-5">
      <div class="bg white flex justify-center flex-col">
        <div class="text-Black text-center align-middle text-3xl font-semibold">
          My Backpack
        </div>
        <MarketBalance />
      </div>

      <TabBar class="left-0 top-0 z-10 mt-6 overflow-x-visible">
        <TabBarItem backpack :to="{ name: 'backpack-ugc' }" label="UGCs" />
        <TabBarItem backpack :to="{ name: 'backpack-badge' }" label="Badges" />
        <TabBarItem backpack :to="{ name: 'backpack-other' }" label="Others" />
      </TabBar>

      <RouterView />
    </div>
  </Layout>
</template>
