import { defineStore } from "pinia";
import { ref } from "vue";
import container from "shared/inversify.config";
import { GlobalSettings, SectionTypeEnum } from "user/models/settings";
import { SettingsApi } from "user/api/settings";

export const DefaultOrder = [
  SectionTypeEnum.FeaturedBanner,
  SectionTypeEnum.TrendingCards,
  SectionTypeEnum.Quests,
  SectionTypeEnum.Referral
]

export const useSettingStore = defineStore("settings", () => {
  const settings = ref<GlobalSettings>({} as GlobalSettings);

  function $reset() {
    settings.value = {} as GlobalSettings;
  }

  async function load(): Promise<GlobalSettings> {
    const res = await container.get(SettingsApi).getSettings();
    const settingsObject: GlobalSettings = {
      ...res,
    };

    if (settingsObject.promo_overlay?.length) {
      const promo = settingsObject.promo_overlay[0];
      settingsObject.ad_button_color = promo.promo_btn_color;
      settingsObject.ad_button_link = promo.promo_btn_url;
      settingsObject.ad_button_text = promo.promo_btn_text;
      settingsObject.ad_poster_image = promo.overlay_image;
      settingsObject.ad_repeat_interval_hours = promo.promo_repeat_interval;
    }

    settings.value = settingsObject;

    return res;
  }

  return {
    $reset,
    load,
    settings: shallowReadonly(settings),
  };
});
