<script setup lang="ts">
import StarOn from "user/assets/star.svg";
import StarOff from "user/assets/star-off.svg";
import CountBear from "user/assets/count-bear.svg";
import { useSessionStore } from "user/stores/session";
import { computedEager } from "@vueuse/core";

const emptyCircle = ref<null | SVGCircleElement>(null);

const formatter = Intl.NumberFormat("en", { notation: "compact" });

const session = useSessionStore();

const stars = computedEager(() => session.currentUser?.currencies?.stars ?? 0);
const ready = ref(false);

const circleAmount = 10000;

// const spinNeedAnimate = (stars.value / circleAmount).toString().split(".")[0];

function animateValue(start: number, end: number, duration: number) {
  let startTimestamp: number | null = null;
  const step = (timestamp: number) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = Math.min((timestamp - startTimestamp) / duration, 1);
    session.starsBalance.totalStars = Math.floor(
      progress * (end - start) + start,
    );

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  };
  window.requestAnimationFrame(step);
}

function animation() {
  animateValue(session.starsBalance.totalStars, stars.value, 1000);
  if (stars.value > session.starsBalance.stars) {
    const difference =
      (stars.value - session.starsBalance.stars) % circleAmount;
    session.starsBalance.position =
      session.starsBalance.position +
      (difference === 0 ? circleAmount : difference);
  } else if (stars.value < session.starsBalance.stars) {
    const difference =
      (session.starsBalance.stars - stars.value) % circleAmount;
    session.starsBalance.position =
      session.starsBalance.position -
      (difference === 0 ? circleAmount : difference);
  }
  ready.value = true;
  session.starsBalance.stars = stars.value;
}

watch(
  () => stars.value,
  () => {
    animation();
  },
);

onMounted(() => {
  if (stars.value !== session.starsBalance.stars) {
    setTimeout(() => {
      animation();
    }, 500);
  }
});
</script>

<template>
  <div class="relative flex w-[224rem] align-middle">
    <div class="left-0 right-0 mx-auto">
      <svg
        viewBox="0 0 34.830986 34.830986"
        class="m-auto h-full max-h-[224px] w-full max-w-[224px]"
        xmlns="http://www.w3.org/2000/svg"
        style="overflow: visible"
      >
        <defs>
          <mask id="myMask">
            <circle cx="17.415493" cy="17.415493" r="17" fill="white" />
            <circle cx="17.415493" cy="4.415493" r="3.1" fill="black" />
            <circle cx="16.815493" cy="4.415493" r="3.1" fill="white" />
          </mask>
          <mask id="myMaskOverlap">
            <circle cx="17.415493" cy="17.415493" r="17" fill="white" />
            <circle cx="16.215493" cy="4.415493" r="4.0" fill="black" />
          </mask>
        </defs>
        <circle
          ref="emptyCircle"
          cx="17.415493"
          cy="17.415493"
          r="13"
          fill="transparent"
          stroke="#ffffff"
          stroke-width="5"
          stroke-opacity="40%"
          mask="url(#myMask)"
        />
        <circle
          cx="17.415493"
          cy="17.415493"
          r="13"
          fill="transparent"
          :mask="'url(#myMaskOverlap)'"
          :stroke="session.starsBalance.stars > 0 ? '#FFD700' : '#ffffff00'"
          stroke-width="5"
          :stroke-dasharray="`${
            (session.starsBalance.stars / circleAmount) *
            (emptyCircle?.getTotalLength() ?? 100)
          } ${(emptyCircle?.getTotalLength() ?? 100) * (1 - session.starsBalance.stars / circleAmount)}`"
          :stroke-dashoffset="`${(emptyCircle?.getTotalLength() ?? 100) / 4}`"
          stroke-linecap="round"
          :style="
            ready ? 'transition: stroke-dasharray 1s ease, mask 1s ease' : ''
          "
        />
        <circle
          cx="17.415493"
          cy="17.415493"
          r="13"
          fill="transparent"
          mask="url(#myMask)"
          :stroke="
            session.starsBalance.stars > 0 && session.starsBalance.stars > 5
              ? '#FFD700'
              : '#ffffff00'
          "
          stroke-width="5"
          :stroke-dasharray="`${
            Math.max(0, (session.starsBalance.stars - 1000) / circleAmount) *
            (emptyCircle?.getTotalLength() ?? 100)
          } ${
            (emptyCircle?.getTotalLength() ?? 100) *
            (1 -
              Math.max(0, (session.starsBalance.stars - 1000) / circleAmount))
          }`"
          :stroke-dashoffset="`${
            (emptyCircle?.getTotalLength() ?? 100) / 4 - 3
          }`"
          :style="
            ready
              ? session.starsBalance.stars < 25
                ? 'transition: stroke-dasharray 2s ease'
                : 'transition: stroke-dasharray 1s ease'
              : ''
          "
        />
        <g
          style="transition: transform 1s ease"
          :transform="`rotate(${
            (session.starsBalance.position / circleAmount) * 360
          }, 17.415493, 17.415493)`"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 17.415493 17.415493"
            dur="1s"
            repeatCount="1"
          />
          <image
            :href="session.starsBalance.stars < 1 ? StarOff : StarOn"
            x="14.3"
            y="1.2"
            width="6.3"
            height="6.3"
          />
        </g>
      </svg>
    </div>
    <div
      class="absolute bottom-0 left-0 right-0 mx-auto flex h-full w-[57rem] flex-col justify-center text-center text-white"
    >
      <img :src="CountBear" class="h-[58rem] w-[57rem]" />
      <div class="text-[27rem] font-bold leading-[30rem] underline">
        <!-- {{ formatter.format(session.starsBalance.stars) }} -->
        {{ formatter.format(session.starsBalance.totalStars) }}
      </div>
      <div class="text-base font-[15rem] leading-[18rem]">STARs</div>
    </div>
  </div>
</template>
