<script setup lang="ts">
import {
  Dialog,
  TransitionChild,
  DialogPanel,
  TransitionRoot,
} from "@headlessui/vue";

const isOpen = defineModel<boolean>("open", { required: true });

function closeModal() {
  emit("close");
  isOpen.value = false;
}

const emit = defineEmits<{
  confirm: [];
  close: [];
}>();
</script>

<template>
  <TransitionRoot :show="isOpen" appear as="template">
    <Dialog as="div" @close="closeModal">
      <TransitionChild
        as="template"
        enter="transition-opacity duration-300 ease-[cubic-bezier(0,0.59,0,1.01)]"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="transition-opacity duration-200 ease-[cubic-bezier(1,0.01,0.88,0.61)]"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="pointer-events-auto fixed inset-0 z-30 h-full w-full bg-mangosteen opacity-90 outline-none"
          tabindex="1"
          @touchend.stop
        />
      </TransitionChild>
      <TransitionChild
        appear
        enter-active-class="duration-300 ease-out transition-transform"
        enter-from-class="-translate-y-[100rem]"
        enter-to-class=" translate-y-0"
        leave-active-class="duration-200 ease-in transition-opacity"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <DialogPanel>
          <div
            class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 flex h-[500rem] w-[325rem] justify-center rounded-[10rem] bg-white text-black backdrop-blur-[7.5px]"
            @click="emit('confirm')"
            v-bind="$attrs"
          >
            <div class="flex max-h-full min-h-0 flex-1 flex-col items-center">
              <slot />
            </div>
          </div>
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
