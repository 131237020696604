<script setup lang="ts">
import { TabGroup, TabPanels, TabPanel } from "@headlessui/vue";
import { useMissionStore } from "user/stores/mission";

const missionStore = useMissionStore();
</script>

<template>
  <!-- tab bar, each tab is a mission list with differnt filters -->
  <div class="relative px-5">
    <div class="flex justify-between pb-5">
      <!-- navigate to missions on click -->
      <div class="text-black sm:text-white mb-2 text-xl font-semibold">
        Quests
      </div>
    </div>
    <TabGroup>
      <NestedTabBar :tabs="['Available', 'In Progress', 'Completed']">
        <TabPanels>
          <TabPanel>
            <MissionList :missions="missionStore.getAvailable" />
          </TabPanel>
          <TabPanel>
            <MissionList :missions="missionStore.getActive" />
          </TabPanel>
          <TabPanel>
            <MissionList :missions="missionStore.getCompleted" />
          </TabPanel>
        </TabPanels>
      </NestedTabBar>
    </TabGroup>
  </div>
</template>
