<script setup lang="ts">
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { ref } from "vue";
import shine from "user/assets/shine.webm";
import { Rarity, Reward, RewardClassName } from "user/models/items";
import { useMarketStore } from "user/stores/marketplace";
import getBackgroundColor from "user/utils/getBackgroundColor";
import { useSessionStore } from "user/stores/session";
import { OnboardingReward } from "user/models/initial-rewards";
import { Currency } from "user/models/currency";

const marketStore = useMarketStore();
const sessionStore = useSessionStore();

const open = defineModel<boolean>("open", { required: true });
const prize = defineModel<Reward | OnboardingReward>("prize", {
  required: true,
});

defineProps<{
  isMarket?: boolean;
  isOnboarding?: boolean;
  sellingPrice?: Currency;
}>();

const emit = defineEmits<{
  goToHome: [];
  goToBackpack: [];
  goToMarket: [];
  continue: [];
  buyAgain: [];
}>();

// if bg depend from reward - need remove initial reward and pass default prize to bgColor
const initialReward: Pick<
  Reward,
  "class_name" | "is_mystery_box_reward" | "rarity"
> & { sellingPrice?: Currency } = {
  is_mystery_box_reward: false,
  rarity: Rarity.default,
  class_name: undefined,
  sellingPrice: undefined,
};

const isAnimated = ref(true); // wait till headlessui transition done

const hideTextMB = ref(false);
const mbAnimationActive = ref(false);
// hideText for hiding lootbox text while lootbox animation
const hideText = ref(false);
const lbAnimationActive = ref(false);
const prizeAnimationActive = ref(false);
// done for hiding reward text while reward animation
const done = ref(false);
const lootboxReward = ref<Reward>();

function startPrizeAnimation() {
  // get the asset, set to true, activate animation
  prizeAnimationActive.value = true;
  prize.value = lootboxReward.value as Reward;
}

function endMysteryBoxAnimation() {
  prize.value.is_mystery_box_reward = false;
}

function setDone() {
  done.value = true;
}
// got some problem - my bg now is changing for bg of reward
function openMysteryBox() {
  hideTextMB.value = true;
  setTimeout(() => {
    mbAnimationActive.value = true;
  }, 100);
}

async function openLootbox() {
  if (isAnimated.value) {
    return;
  }
  const resp = await marketStore.openLootbox(prize.value.id);
  lootboxReward.value = resp;
  handleLootbox();
  await sessionStore.tryFetchCurrentUser();
}

function goToHome() {
  emit("goToHome");
}

function goToBackpack() {
  emit("goToBackpack");
}

function goToMarket() {
  emit("goToMarket");
}

function handleLootbox() {
  hideText.value = true;
  setTimeout(() => (lbAnimationActive.value = true), 100);
}

watch(open, () => {
  if (
    open.value &&
    prize.value.class_name !== RewardClassName.Lootbox &&
    !prize.value.is_mystery_box_reward
  ) {
    prizeAnimationActive.value = true;
  }
});

watch(
  () => prize.value.is_mystery_box_reward,
  () => {
    if (prize.value.class_name !== RewardClassName.Lootbox) {
      prizeAnimationActive.value = true;
    }
  },
);

onMounted(() => {
  setTimeout(() => {
    isAnimated.value = false;
  }, 1000);

  initialReward.is_mystery_box_reward = prize.value.is_mystery_box_reward;
  initialReward.rarity = prize.value.rarity;
  initialReward.class_name = prize.value.class_name;
  initialReward.sellingPrice = prize.value.price;

  if (
    open.value &&
    prize.value.class_name !== RewardClassName.Lootbox &&
    !prize.value.is_mystery_box_reward
  ) {
    prizeAnimationActive.value = true;
  }
});
</script>

<template>
  <TransitionRoot :show="open">
    <Dialog as="div" class="relative z-[70]" @close="open = false">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 z-30 w-full h-full"
          :class="[
            getBackgroundColor(
              initialReward.rarity,
              'radial',
              initialReward.class_name,
              initialReward.is_mystery_box_reward,
            ),
          ]"
        />
      </TransitionChild>

      <TransitionChild
        as="template"
        enter="duration-300 ease-out transition-opacity"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in transition-opacity"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <DialogPanel
          class="fixed py-10 left-0 right-0 top-0 z-50 m-auto flex h-full w-[90vw] flex-col items-center justify-between"
        >
          <TransitionChild
            as="template"
            enter="duration-300 ease-out transition-opacity delay-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="duration-200 ease-in transition-opacity"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <video
              alt="shine"
              autoplay
              loop
              :src="shine"
              class="fixed -z-10 -top-5 h-[calc(100%+20rem)] w-full bg-transparent object-cover"
            />
          </TransitionChild>
          <ReceiveMysteryBoxModal
            v-if="prize.is_mystery_box_reward"
            :prize="prize"
            :hide-text-m-b="hideTextMB"
            :mb-animation-active="mbAnimationActive"
            :is-animated="isAnimated"
            @open-mystery-box="openMysteryBox"
            @end-mystery-box-animation="endMysteryBoxAnimation"
          />
          <ReceiveRewardModal
            v-else-if="prize.class_name !== RewardClassName.Lootbox"
            :prize="prize"
            :prize-animation-active="prizeAnimationActive"
            :done="done"
            @go-to-backpack="goToBackpack"
            @go-to-home="goToHome"
            @go-to-market="goToMarket"
            @set-done="setDone"
            @continue="emit('continue')"
            @buy-again="emit('buyAgain')"
            :is-market="isMarket"
            :is-onboarding="isOnboarding"
            :selling-price="initialReward.sellingPrice"
          />
          <ReceiveLootboxModal
            v-else-if="prize.class_name === RewardClassName.Lootbox"
            :prize="prize"
            :hide-text="hideText"
            :lb-animation-active="lbAnimationActive"
            :is-animated="isAnimated"
            :is-market="isMarket"
            @open-lootbox="openLootbox"
            @start-prize-animation="startPrizeAnimation"
            :selling-price="initialReward.sellingPrice"
          />
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>
