<script setup lang="ts">
import timer from "user/assets/timer.svg";

const props = defineProps<{
  formattedDuration: string;
  freeSpinAvailable: boolean;
  spinVouchers: number;
}>();

const spinCount = computed(() => {
  if (props.spinVouchers > 0) {
    return props.spinVouchers;
  }

  if (props.freeSpinAvailable && props.spinVouchers == 0) {
    return 1;
  }

  return 0;
});
</script>

<template>
  <div class="flex flex-col gap-4 justify-center items-center">
    <div
      v-if="spinVouchers || freeSpinAvailable"
      class="flex flex-col font-semibold"
    >
      <span
        class="text-[30rem]/[30rem] md:text-[30rem]/[50rem] lg:text-[50rem]/[70rem] text-center text-transparent bg-aquaToPurple bg-clip-text"
      >
        Available Spins: <span class="text-white">{{ spinCount }}</span>
      </span>
    </div>
    <div v-if="!freeSpinAvailable" class="flex flex-col font-semibold gap-2">
      <span
        class="text-[30rem]/[30rem] text-center text-transparent bg-gradient-to-r from-[#FFF3B3] to-[#E3B253] bg-clip-text"
      >
        {{ freeSpinAvailable ? "SPIN NOW" : "NEXT SPIN" }}
      </span>
      <div
        class="flex rounded-ml bg-20000Leagues text-[20rem]/[40rem] text-white border-[3rem] pl-4 pr-2"
      >
        <div class="flex md:w-[140rem] gap-2">
          <img :src="timer" alt="timer" class="w-[20rem]" />
          {{ formattedDuration }}
        </div>
      </div>
    </div>
  </div>
</template>
